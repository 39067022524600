import React, { useEffect, useState } from "react";
import { Table, Tooltip, Button, message, Modal, Form, Popconfirm } from "antd";
import {
  EditOutlined,
  FunnelPlotOutlined,
  DownloadOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { getList } from "../../../actions/dataActions";
// import CuSoModal from "./salesOrders.createOrUpdate";
import FillSoModal from "./salesOrders.fillOrder";
import {
  DATE_TIME_FORMAT,
  FILL_STATUS,
  PAYMENT_TERMS,
} from "../../../assets/constants";
import fillStatusIcon from "../../../common/components/statusIcons";
import LocationSelect from "../../../common/components/locationSelect";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { isEmpty } from "../../../common/functions/validateInputs";
import SalesOrderForm from "./salesOrder.createOrUpdate";
// import { CSVLink } from 'react-csv';


const SalesOrders = ({
  getSalesOrders,
  getCustomers,
  getTransporters,
  getSkus,
  getMaterials,
  getPaymentTerms,
  customers = [],
  salesOrders = [],
  // skus = [],
  selectedLocation,
}) => {
  const [showCuModal, setShowCuModal] = useState(false); //Cu = Create/Update
  const [showFillModal, setShowFillModal] = useState(false);
  const [selectedSo, setSelectedSo] = useState({});

  const [toFillStatus, setToFillStatus] = useState(false);

  const [localSalesOrders, setLocalSalesOrders] = useState([]);
  // state managed for filtering
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [form] = Form.useForm();

  const locationId = selectedLocation?.id;
  const locationName = selectedLocation?.name;

  useEffect(() => {
    listApiCall();
  }, []);

  useEffect(() => {
    if (salesOrders.length) {
      let _temp = salesOrders.filter((el) => el.location_id === locationId);
      _temp = _temp.map((el) => ({
        ...el,
        customer_name: el.customer.name,
        contact: el.customer_address.contact,
      }));
      setLocalSalesOrders(_temp);
    }
  }, [salesOrders, locationId]);

  const createEditSoHandler = async (_finalObj) => {
    let _mandatoryFields = {
      due_date: _finalObj.due_date,
      customer_id: _finalObj.customer_id,
      location_id: _finalObj.location_id,
      payment_terms: _finalObj.payment_terms,
      total_amount: _finalObj.total_amount,
    };

    let error = isEmpty(_mandatoryFields);

    try {
      if (error) {
        setShowCuModal(true);
        message.error(`${error}`);
      }
      if (!error) {
        const isEditMode = _finalObj?.id;
        const url = `/api/sales/so/${isEditMode ? "edit" : "create"}`;
        const toFill = _finalObj.to_fill;

        const _actualFinalObject = JSON.parse(JSON.stringify(_finalObj));
        delete _actualFinalObject.to_fill;
        const response = await axios.post(url, _actualFinalObject);

        if (!isEditMode && toFill) {
          const detail = response.data.detail[0]; 
          const items = detail.items || [];
          const fills = items.map(
            ({ item_id, item_type, quantity, pending, uom }) => ({
              item_id,
              item_type,
              quantity,
              fill_quantity: pending,
              uom,
            })
          );

          const finalObject = {
            fills,
            so_id: detail.id,
          };
          const { data } = await axios.post(`/api/sales/so/fill`, finalObject);
        }

        setShowCuModal(false);
        getSalesOrders();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data?.msg || "An error occurred.";
        window.alert(errorMessage);
      } else {
        console.error("An unexpected error occurred:", error);
        window.alert("An unexpected error occurred. Please try again.");
      }
    }

  };

  const revertSoHandler = async (salesOrderId) => {
    try {
      await axios.post(`/api/sales/so/revert/${salesOrderId}`);
      await listApiCall();
      setShowCuModal(false);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const deleteSoHandler = async (salesOrderId) => {
    try {
      await axios.delete(`/api/sales/so/${salesOrderId}`);
      await listApiCall();
      setShowCuModal(false);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const fillSoCall = async (_finalObj) => {
    try {
      const response = await axios.post(`/api/sales/so/fill`, _finalObj);

      setShowFillModal(false);
      getSalesOrders();
    } catch (error) {
      window.alert(error.message);
    }
  };

  const listApiCall = async () => {
    getSalesOrders();
    getCustomers();
    getTransporters();
    // getSuppliers();
    getMaterials();
    getSkus();
    getPaymentTerms();
  };

  const editSoClick = (_salesOrder) => {
    setSelectedSo(_salesOrder);
    setShowCuModal(true);
  };

  const fillSoClick = (_salesOrder) => {
    setSelectedSo(_salesOrder);
    setShowFillModal(true);
  };

  const [deletePopup, setDeletePopup] = useState(false);

  const showPopconfirm = () => {
    setDeletePopup(true);
  };

  const columns = [
    {
      title: "Order #",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,

      ...useTableSearch("id"),
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      ...useTableSearch("customer_name"),
    },

    {
      title: "Total Amount",
      dataIndex: "total_amount",
      sorter: (a, b) => a.total_amount - b.total_amount,
      render: (value) => `₹ ${value?.toLocaleString("en-IN")}`,
    },
    {
      title: "Payment Terms",
      dataIndex: "payment_terms",
      filters: PAYMENT_TERMS,
      filterMultiple: false,
      onFilter: (value, record) => record.payment_terms.indexOf(value) === 0,
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      render: (value) => moment(value).format(DATE_TIME_FORMAT),
      defaultSortOrder: "descend",
      sorter: (x, y) =>
        moment(x?.created_date).unix() - moment(y?.created_date).unix(),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      render: (value) => moment(value).format(DATE_TIME_FORMAT),
      sorter: (x, y) => moment(x?.due_date).unix() - moment(y?.due_date).unix(),
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      // filters: FILL_STATUS,
      // onFilter: (value, record) => record?.fill_status?.indexOf(value) === 0,
      // render: (value) => fillStatusIcon(value),
    },
    {
      title: "Sale Status",
      dataIndex: "fill_status",
      filters: FILL_STATUS,
      onFilter: (value, record) => record?.fill_status?.indexOf(value) === 0,
      render: (value) => fillStatusIcon(value),
    },
    {
      title: "Delivery & Invoice",
      dataIndex: "fill_status",
      filters: FILL_STATUS,
      onFilter: (value, record) => record?.fill_status?.indexOf(value) === 0,
      render: (value) => {
        return <div>Delivered, Invoice Not Acknowledged</div>;
      },
    },
    {
      title: "Actions",
      width: 160,
      render: (value, record) => {
        return (
          <>
            <Tooltip title="Revert">
              <Popconfirm
                placement="left"
                title="Do you wish to revert the changes?"
                okText="Revert"
                cancelText="Cancel"
                onConfirm={() => revertSoHandler(record?.id)}
                onCancel={() => setDeletePopup(false)}
              >
                <Button
                  type="dashed"
                  disabled={record?.order_status?.toLowerCase() === "reverted"}
                  shape="circle"
                  icon={<UndoOutlined />}
                  onClick={showPopconfirm}
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                disabled={
                  record?.fill_status === "Completed" || 
                  record?.fill_status === "Partially Filled" ||
                  record?.order_status?.toLowerCase() === "reverted"
                }
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => editSoClick(record)}
                danger
              />
            </Tooltip>
            <Tooltip title="Fill">
              <Button
                disabled={
                  record?.fill_status === "Completed" ||
                  record?.order_status?.toLowerCase() === "reverted"
                }
                type="primary"
                shape="circle"
                icon={<FunnelPlotOutlined />}
                onClick={() => fillSoClick(record)}
              />
            </Tooltip>
            <Tooltip title="Download">
              <Button
                danger
                shape="circle"
                icon={<DownloadOutlined />}
                onClick={() => {
                  window.open(`/invoices/sales/${record.id}`, "_blank");
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleCancel = () => {
    setShowCuModal(false);
    setSelectedSo({});
    form.resetFields();
  };

  return (
    <>
      <div className="flex justify-between">
        <LocationSelect />
        <div>
        <Button type="primary" onClick={() => setShowCuModal(true)}>
          + New SO for {locationName}
        </Button>

        {/* <Button style={{marginLeft: "0.5rem"}}>
        <CSVLink data={localSalesOrders} filename={"filename.csv"}>Export</CSVLink>
        </Button> */}

        </div>


        {/* <Button type="primary" onClick={() => setShowCuModal(true)}>
          + New SO{" "}
        </Button> */}
      </div>
      <br />
      <Table
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>
              <b>Comment added: </b>
              {record.comments}
            </p>
          ),
          rowExpandable: (record) => record.comments,
        }}
        scroll={{
          x: true,
        }}
        // loading={isLoading}
        columns={columns}
        dataSource={localSalesOrders}
      />

      <Modal
        destroyOnClose
        style={{ top: 10 }}
        title={`${selectedSo?.id ? "Update" : "Create a new"} Sales Order`}
        visible={showCuModal}
        footer={null}
        onCancel={handleCancel}
        onOk={createEditSoHandler}
        width={"100%"}
      >
        {showCuModal && (
          <SalesOrderForm
            form={form}
            locationId={locationId}
            onDelete={deleteSoHandler}
            so={selectedSo}
            onCancel={handleCancel}
            onOk={createEditSoHandler}
          />
        )}
      </Modal>

      {/* {showCuModal && (
        <CuSoModal
          locationId={locationId}
          open={showCuModal}
          so={selectedSo}
          onDelete={deleteSoHandler}
          onClose={() => {
            setShowCuModal(false);
            setSelectedSo({});
          }}
          onOk={createEditSoHandler}
        />
      )} */}

      {showFillModal && (
        <FillSoModal
          locationId={locationId}
          open={showFillModal}
          so={selectedSo}
          onClose={() => {
            setShowFillModal(false);
            setSelectedSo({});
          }}
          onOk={(_finalObj) => fillSoCall(_finalObj)}
        />
      )}
    </>
  );
};

const mapState = (state) => {
  return state.data;
};
const mapDispatch = {
  getSalesOrders: () => getList("salesOrders"),
  getCustomers: () => getList("customers"),
  getTransporters: () => getList("transporters"),
  getSkus: () => getList("skus"),
  getMaterials: () => getList("materials"),
  getPaymentTerms: () => getList("paymentTerms"),
};

export default connect(mapState, mapDispatch)(SalesOrders);
