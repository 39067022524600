import React, { useEffect, useState } from "react";
import { Table, Tooltip, Button, Modal } from "antd";
import {
  EditOutlined,
  FunnelPlotOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import moment from "moment";

import {
  PAYMENT_TERMS,
  FILL_STATUS,
  DATE_TIME_FORMAT,
} from "../../../assets/constants";
import { connect } from "react-redux";
import { getList } from "../../../actions/dataActions";
import CreateOrUpdate from "./purchaseOrders.createOrUpdate";
import Fill from "./purchaseOrders.fill";
import fillStatusIcon from "../../../common/components/statusIcons";
import LocationSelect from "../../../common/components/locationSelect";
import useTableSearch from "../../../common/hooks/useTableSearch";

const PurchaseOrders = ({
  isLoading,
  getPurchaseOrders,
  purchaseOrders = [],
  getSuppliers,
  suppliers = [],
  getTransporters,
  transporters = [],
  getMaterials,
  materials = [],
  history,
  locationId,
  locationName,
  getPaymentTerms,
  paymentTerms,
}) => {
  const [showCuModal, setShowCuModal] = useState(false); //Cu = Create/Update
  const [showFillModal, setShowFillModal] = useState(false);
  const [selectedPo, setSelectedPo] = useState({});

  const [localPurchaseOrders, setLocalPurchaseOrders] = useState([]); // state managed for filtering

  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    getMaterials();
    getPurchaseOrders();
    getSuppliers();
    getTransporters();
    getPaymentTerms();
  }, []);

  useEffect(() => {
    if (purchaseOrders.length) {
      const filtered = purchaseOrders.filter(
        (el) => el.location_id === locationId
      );
      setLocalPurchaseOrders(filtered);
    }
  }, [purchaseOrders, locationId]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      defaultSort: "desc",
      width: 50,
      ...useTableSearch("id"),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Supplier",
      width: 150,
      dataIndex: "supplier_id",
      render: (id) => <b>{suppliers.find((el) => el?.id === id)?.name}</b>,
    },
    {
      title: "Materials",
      render: (id, record) => (
        <div>
          {
            materials?.find((el) => el.id === record.items[0]?.material_id)
              ?.name
          }
          {record.items?.length > 1 && (
            <b style={{ cursor: "pointer" }}>
              <Tooltip
                title={
                  <>
                    <strong>Additional Materials:</strong>
                    {record?.items?.slice(1).map((el) => (
                      <div key={el.material_id}>
                        {materials.find((m) => m.id === el.material_id)?.name}
                      </div>
                    ))}
                  </>
                }
              >
                &nbsp;+{record.items.length - 1}
              </Tooltip>
            </b>
          )}
        </div>
      ),
    },
    {
      title: "Quantity and Unit",
      render: (value, record) => {
        const totalQuantity = record.items.reduce(
          (acc, item) => acc + item.quantity,
          0
        );
        const unit = record.items[0]?.uom || "-";
        return `${totalQuantity} ${unit}`;
      },
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      render: (amount) => "₹ " + amount?.toLocaleString("en-IN"),
      sorter: (a, b) => a.total_amount - b.total_amount,
    },
    {
      title: "Payment Terms",
      dataIndex: "payment_terms",
      filters: PAYMENT_TERMS,
      filterMultiple: true,
      onFilter: (value, record) => record.payment_terms.indexOf(value) === 0,
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      render: (value) => moment(value).format(DATE_TIME_FORMAT),
      defaultSortOrder: "descend",
      sorter: (x, y) =>
        moment(x?.created_date).unix() - moment(y?.created_date).unix(),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      render: (value) => moment(value).format(DATE_TIME_FORMAT),
      sorter: (x, y) => moment(x?.due_date).unix() - moment(y?.due_date).unix(),
    },
    {
      title: "Receipt Status",
      dataIndex: "fill_status",
      filters: FILL_STATUS,
      onFilter: (value, record) => record?.fill_status?.indexOf(value) === 0,
      render: (value) => fillStatusIcon(value),
    },
    {
      title: "Actions",
      width: 150,
      render: (value, record) => {
        return (
          <>
            <Tooltip title="Edit">
              <Button
                disabled={
                  record?.fill_status === "Completed" ||
                  record?.fill_status === "Partially Filled"
                }
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectedPo(record);
                  setShowCuModal(true);
                }}
                danger
              />
            </Tooltip>
            <Tooltip title="Fill">
              <Button
                disabled={record?.fill_status === "Completed"}
                type="primary"
                shape="circle"
                icon={<FunnelPlotOutlined />}
                onClick={() => {
                  setSelectedPo(record);
                  setShowFillModal(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Download">
              <Button
                danger
                shape="circle"
                icon={<DownloadOutlined />}
                onClick={() => {
                  window.open(`/invoices/purchase/${record.id}`, "_blank");
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex justify-between">
        <LocationSelect />

        <Button type="primary" onClick={() => setShowCuModal(true)}>
          + New PO for {locationName}
        </Button>
      </div>
      <br />
      <Table
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>
              <b>Comment added:</b>
              {record.comments}
            </p>
          ),
          rowExpandable: (record) => record.comments,
        }}
        scroll={{
          x: true,
        }}
        loading={isLoading}
        columns={columns}
        dataSource={localPurchaseOrders}
      />

      {/* PO Create/Update Modal */}

      <Modal
        destroyOnClose={true}
        title={"Purchase order for " + locationName}
        visible={showCuModal}
        onCancel={() => {
          setShowCuModal(false);
          setSelectedPo({});
        }}
        confirmLoading={confirmLoading}
        footer={null}
        width={"100%"}
        style={{ top: 2 }}
      >
        {showCuModal && (
          <CreateOrUpdate
            locId={locationId}
            onOk={getPurchaseOrders}
            onCancel={() => {
              setShowCuModal(false);
              setSelectedPo({});
            }}
            po={selectedPo}
          />
        )}
      </Modal>

      {/* @ Fill Modal */}

      <Modal
        title="Fill Card"
        visible={showFillModal}
        destroyOnClose={true}
        onCancel={() => {
          setShowFillModal(false);
          setSelectedPo({});
        }}
        width={1200}
        footer={null}
      >
        {showFillModal && (
          <Fill
            onOk={() => {
              getPurchaseOrders();
              setShowFillModal(false);
              setSelectedPo();
            }}
            po={selectedPo}
            onCancel={() => {
              setShowFillModal(false);
              setSelectedPo({});
            }}
          />
        )}
      </Modal>
    </>
  );
};

const mapState = (state) => {
  const { isLoading, purchaseOrders, suppliers, materials, selectedLocation } =
    state.data;
  return {
    isLoading,
    purchaseOrders,
    suppliers,
    materials,
    locationId: selectedLocation?.id,
    locationName: selectedLocation?.name,
    paymentTerms: state.data.paymentTerms,
  };
};
const mapDispatch = {
  getPurchaseOrders: () => getList("purchaseOrders"),
  getSuppliers: () => getList("suppliers"),
  getMaterials: () => getList("materials"),
  getTransporters: () => getList("transporters"),
  getPaymentTerms: () => getList("paymentTerms"),
};

export default connect(mapState, mapDispatch)(PurchaseOrders);
