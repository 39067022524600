import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Table } from "antd";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { DATE_TIME_FORMAT } from "../../../assets/constants";

const GoodsIssuedNotes = () => {
  const [list, setList] = useState([]);
  const [skus, setSkus] = useState([]);

  const COL = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      ...useTableSearch("id"),
    },
    {
      title: "M.Order ID",
      dataIndex: "mo_id",
      width: 100,
      key: "mo_id",
      ...useTableSearch("mo_id"),
    },
    {
      title: "Batch No.",
      dataIndex: "batch_number",
      ...useTableSearch("batch_number"),
    },
    {
      title: "SKU",
      dataIndex: "sku_id",
      key: "sku_id",
      render: (text) => <b>{skus.find((el) => el.id === text)?.sku}</b>,
    },
    {
      title: "Date of creation",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      sorter: (x, y) => moment(x?.date).unix() - moment(y?.date).unix(),
      render: (text) => moment(text).format(DATE_TIME_FORMAT),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => text + " Pc",
    },
    // {
    //   title: "Material Cost",
    //   dataIndex: "material_cost",
    //   render: (text) => text + " INR",
    // },
    // {
    //   title: "Labour Cost",
    //   dataIndex: "labour_cost",
    //   render: (text) => text + " INR",
    // },
  ];

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const { data } = await axios.get("/api/make/gin/list");
      const skuData = await axios.get("/api/goods/sku/list");
      setList(data);
      setSkus(skuData.data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  return (
    <div>
      <Table dataSource={list} columns={COL} />
    </div>
  );
};

export default GoodsIssuedNotes;
