import React, { useEffect, useState } from "react";
import { Table, Tooltip, Button, Input, Space } from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";

const GRNSummary = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = React.useRef();
  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const { data } = await axios.get("/api/purchase/grn/list");
      setData(
        data.map((item) => ({
          ...item,
          grn_id: item.grn_t?.grn_id || "N/A", // Flatten grn_id
          po_id: item.grn_t?.po_id || "N/A", // Flatten po_id
          partner_name: item.partner?.name || "N/A", // Flatten partner name
          total_amount: item.po?.total_amount || 0, // Flatten total_amount
        }))
      );
    } catch (error) {
      window.alert(error.message);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };


const handleReset = (clearFilters) => {
  clearFilters();
  setSearchText("");
  setSearchedColumn("");
  getList(); 
};
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "GRN #",
      dataIndex: "grn_id",
      key: "grn_id",
      ...getColumnSearchProps("grn_id"),
    },
    {
      title: "PO #",
      dataIndex: "po_id",
      key: "po_id",
      ...getColumnSearchProps("po_id"),
    },
    {
      title: "Supplier Name",
      dataIndex: "partner_name",
      key: "partner_name",
      ...getColumnSearchProps("partner_name"),
    },

    {
      title: "Amount of PO",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text) => `${text} INR`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (value, record) => (
        <Tooltip title="Download">
          <Button
            danger
            shape="circle"
            icon={<DownloadOutlined />}
            onClick={() =>
              window.open(`/invoices/grn/${record.grn_id}`, "_blank")
            }
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={data} columns={columns} rowKey="grn_id" />
    </div>
  );
};

export default GRNSummary;

