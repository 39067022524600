/** @module UtilityFunctions **/

/**
 * @function formatGroups
 * @description Converts groups & subgroups raw array into table usable array
 * @param {Array} obj - Array of Objects containing Groups and Subgroups data
 * @memberof UtilityFunctions
 * @example formatGroups(groupList);
 */

export function formatGroups(arr) {
  let finalArr = [];
  if (arr) {
    arr.map((obj) => finalArr.push({ text: obj.name, value: obj.name ,id: obj.id || "",main_group_id: obj.main_group_id || ""}));
  }
  return finalArr;
}
