import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Tooltip,
  Button,
  Row,
  Col,
  Modal,
  Input,
  Space,
  DatePicker,
  message,
} from "antd";
import { ScissorOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import LocationSelect from "../../../common/components/locationSelect";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { isEmpty } from "../../../common/functions/validateInputs";

const DATE_FORMAT = "DD-MM-YYYY hh:mm:ss";

const SKUInvSummary = ({ locationId }) => {
  //table data
  const [data, setData] = useState([]);

  //Stock Adjustment Card Modal
  const [adjustVisible, setAdjustVisible] = useState(false);
  const [adjustLoading, setAdjustLoading] = useState(false);
  const [adjustModalData, setAdjustModalData] = useState([]);
  console.log(adjustModalData, "adjustModalData ");
  //filters
  const [viewat, setViewat] = useState(moment(new Date().toISOString()));

  useEffect(() => {
    listAPICall();
  }, []);

  useEffect(() => {
    listAPICall();
    //eslint-disable-next-line
  }, [locationId]);

  const listAPICall = async (date = viewat) => {
    let { data } = await axios.post(`/api/inventory/sku/list`, {
      location_id: locationId,
      inv_date: date,
    });

    setData(data);
  };

  const columns = [
    { title: "SKU ID", dataIndex: "sku_id", ...useTableSearch("sku_id") },
    {
      title: "SKU Name",
      dataIndex: "sku",
      ...useTableSearch("sku"),
      render: (value) => <b>{value}</b>,
    },
    {
      title: "In Stock",
      dataIndex: "instock",
      filters: [{ text: "Select Only Non-zero Stock", value: 0 }],
      onFilter: (value, record) => record.instock !== 0,
    },
    // {
    //   title: "Average Value",
    //   dataIndex: "avg_value",
    //   render: (value) => parseFloat(value.toFixed(2)),
    // },
    // { title: "Total Value", dataIndex: "total_value" },
    { title: "Committed Stock", dataIndex: "committed" },
    { title: "Available Stock", dataIndex: "available" },

    {
      title: "Actions",
      render: (value, record) => {
        return (
          <>
            <Tooltip title="Adjust">
              <Button
                type="primary"
                shape="circle"
                icon={<ScissorOutlined />}
                onClick={() => handleAdjustCard(record)}
                danger
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleAdjustCard = (x) => {
    setAdjustVisible(true);
    x.location_id = locationId;
    x.inv_date = moment().toISOString();
    x.adjust = 0;
    setAdjustModalData(x);
  };

  const handleAdjustOk = async () => {
    setAdjustLoading(true);

    let apiRequest = {
      sku_id: adjustModalData.sku_id,
      location_id: adjustModalData.location_id,
      inv_date: adjustModalData.inv_date,
      material_id: adjustModalData.material_id,
      quantity: adjustModalData.adjust - adjustModalData.instock,
      value:
        adjustModalData.avg_value *
        (adjustModalData.adjust - adjustModalData.instock),
    };

    let _mandatoryFields = {
      adjust_current_stock_to: adjustModalData.adjust,
      // inv_date: adjustModalData.inv_date,
      // material_id: adjustModalData.material_id,
      // quantity: adjustModalData.adjust - adjustModalData.instock,
      // value:
      //   adjustModalData.avg_value *
      //   (adjustModalData.adjust - adjustModalData.instock),
    };

    let error = isEmpty(_mandatoryFields);

    if (error) {
      setAdjustVisible(true);
      message.error(`${error}`);
    }
    if (!error) {
      await axios.post(`/api/inventory/sku/adjust`, apiRequest);
      setAdjustVisible(false);
    }
    setAdjustLoading(false);
    listAPICall(adjustModalData?.inv_date);
    message.success("Stock Data Adjusted Successfully");
  };

  const handleAdjustCancel = () => {
    setAdjustVisible(false);
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          {/* Inventory Location: */}
          <LocationSelect />
        </Col>
        <Col span={6} offset={12}>
          View At :{" "}
          <DatePicker
            showTime
            disabledDate={(d) => !d || d.isAfter(moment())}
            allowClear={false}
            style={{ width: "100%" }}
            value={moment(viewat, DATE_FORMAT)}
            format={DATE_FORMAT}
            onChange={(dt, dtstr) => {
              const _date = moment(dt).toISOString();
              setViewat(_date);
              listAPICall(_date);
            }}
          />
        </Col>
      </Row>
      <br />
      <Table columns={columns} dataSource={data} />
      <Modal
        title="Stock Adjustment Card"
        visible={adjustVisible}
        confirmLoading={adjustLoading}
        onOk={handleAdjustOk}
        onCancel={handleAdjustCancel}
        width={800}
      >
        <br />

        <Row gutter={0}>
          <Col span={24} offset={0}>
            <p>SKU Name</p>
            <Input
              style={{ width: "100%" }}
              value={adjustModalData.sku}
              disabled
            />
          </Col>
        </Row>
        <br />

        <Row gutter={0}>
          <Col span={6} offset={0}>
            <div style={{ display: "flex" }}>
              <p>Adjust Current Stock To</p>
              <p
                style={{
                  color: "crimson",
                  fontSize: "22px",
                  margin: "-5px 0 0 5px",
                }}
              >
                *
              </p>
            </div>
            <Input
              value={adjustModalData.adjust}
              onChange={(v) => {
                setAdjustModalData({
                  ...adjustModalData,
                  ...{
                    adjust: v.target.value,
                  },
                });
              }}
            />
          </Col>
          <Col span={6} offset={3}>
            <div style={{ display: "flex" }}>
              <p>Quality Change</p>
            </div>
            <Input
              suffix="units"
              style={{ width: "100%" }}
              value={adjustModalData.adjust - adjustModalData.instock}
              disabled
            />
          </Col>
          {/* <Col span={6} offset={3}>
            <div style={{ display: "flex" }}>
              <p>Value Change</p>
            </div>
            <Input
              suffix="INR"
              style={{ width: "100%" }}
              value={
                adjustModalData.avg_value *
                (adjustModalData.adjust - adjustModalData.instock)
              }
              disabled
            />
          </Col> */}
          <Col span={6} offset={3}>
            <p>In Stock</p>
            <Input
              suffix="units"
              style={{ width: "100%" }}
              value={
                !adjustModalData.adjust
                  ? adjustModalData.instock
                  : adjustModalData.adjust
              }
              disabled
            />
          </Col>
        </Row>
        <br />

        <Row gutter={0}>
          <Col span={6} offset={0}>
            <p>Committed</p>

            <Input
              suffix="units"
              style={{ width: "100%" }}
              value={adjustModalData.committed}
              disabled
            />
          </Col>
          <Col span={6} offset={3}>
            <p>Available</p>
            <Input
              suffix="units"
              style={{ width: "100%" }}
              value={
                !adjustModalData.adjust
                  ? adjustModalData.available
                  : adjustModalData.adjust - adjustModalData.committed
              }
              disabled
            />
          </Col>
          <Col span={6} offset={3}>
            <div style={{ display: "flex" }}>
              <p>Adjust Date</p>
            </div>
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              value={moment(adjustModalData.inv_date, "YYYY/MM/DD")}
              disabled
            />
          </Col>
        </Row>
        <br />

        {/* <br />
        <Row gutter={0}>
          <Col span={6} offset={0}>
            <div style={{ display: "flex" }}>
              <p>Location Name</p>
            </div>
            <Input
              style={{ width: "100%" }}
              value={adjustModalData.location_name}
              disabled
            />
          </Col>
        </Row>
        <br /> */}
      </Modal>
    </>
  );
};

const mapState = (state) => {
  return {
    locationId: state.data?.selectedLocation?.id,
  };
};

export default connect(mapState, null)(SKUInvSummary);
