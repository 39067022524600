export const validateSkusInFill = (skusData) => {
  for (const { fill_quantity, quantity, pending } of skusData) {
    if (!fill_quantity || parseFloat(fill_quantity) === 0) {
      return "Fill quantity cannot be empty or zero";
    }
    if (
      parseFloat(fill_quantity) > parseFloat(quantity) ||
      parseFloat(fill_quantity) > parseFloat(pending)
    ) {
      return "Fill quantity cannot be greater than the total or pending quantity";
    }
    return null;
  }
};
