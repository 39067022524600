import {
  AutoComplete,
  Form,
  Input,
  Modal,
  Button,
  message,
  Select,
  Row,
  Col,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { skuType } from "../../sales/sales-orders/itemTypes";

const PriceModal = ({
  isModalVisible,
  setModalVisible,
  typeOfModal,
  setTypeOfModal,
  selectedItem,
  setSelectedItem,
  selectedType, 
  setSelectedType,
  getMaterialsList,
  getSkusList
}) => {
  const [id, setId] = useState(null);
  const [type, setType] = useState("");
  const [price, setPrice] = useState(null);

  useEffect(() => {

      setId(selectedItem?.id)
      setType(selectedType)

  }, [selectedType, selectedItem])

  const cancelHandler = () => {
    setModalVisible(false);
  };


  const onOkHandler = async () => {

    if(price<=0){
        message.error("Price should be greater than 0")
        return
    }
    const finalObject = {
      item_id: id,
      item_type: selectedType,
      value: price,
    };

    console.log(finalObject, "final")

    try {
        const res = await axios.post("/api/price", finalObject);
        console.log(res, "response");
        if(res.status === 200) {
            message.success("Added the Price of Item");
            setId(null);
            setType("");
            setPrice(null);
            getMaterialsList();
            getSkusList();
            setModalVisible(false);
        }
    }catch(error){
        message.error("Error Updating price, Please try again later")
    }
  };
  return (
    <Modal
      title="Add Prices"
      visible={isModalVisible}
      onOk={onOkHandler}
      onCancel={cancelHandler}
    >
      <Form layout="vertical">
        <Row>
          <Col offset={1}>
            <Form.Item label="Item Id" required tooltip="e.g., 1">
              <Input
                disabled
                placeholder="Item Id"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={1}>
            <Form.Item label="Type" required tooltip="e.g., material">
              <Input
                disabled
                value={type}
                placeholder="Item Type"
                onChange={(e) => setType(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Col offset={1}>
          <Form.Item label="price" required tooltip="e.g., 120">
            <Input
              required
              placeholder="Price of Item"
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};

export default PriceModal;
