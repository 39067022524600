export const PAYMENT_TERMS = [{}];
export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
export const validateContact = (input) => {
  const isNumeric = /^\d*$/.test(input);
  const isValidLength = /^(\d{6}|\d{10})$/.test(input);

  return { isNumeric, isValidLength };
};
