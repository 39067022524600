import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Form,
  Input,
  Modal,
  Button,
  message,
  Select,
  Row,
  Col,
} from "antd";
import axios from "axios";
import { getList } from "../../../actions/dataActions";
import { connect } from "react-redux";

let nameoptions = [];

let typeoptions = [];
const othersObject = {
  name: "Others",
  value: -1,
};

const filterOptionWithGroups = (input, option) => {
  if (option.children) {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      ? true
      : false;
  } else if (option.label) {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      ? true
      : false;
  }
};

const filterOption = (input, option) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

function CreateUpdateTaxModal({
  setSelectedTax,
  setModalVisible,
  setTypeOfModal,
  selectedTax,
  typeOfModal,
  isModalVisible,

  getTaxes,
  taxNames,
  taxTypes,
}) {
  const [form] = Form.useForm();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [name, setName] = useState("");
  const [newName, setNewName] = useState("");

  const [type, setType] = useState("");
  const [newType, setNewType] = useState("");

  const [value, setValue] = useState("");

  const [code, setCode] = useState("");
  const [resultoptions, setResultOptions] = useState([]);
  const [errors, setErrors] = useState([]);

  const validateInputs = (typeVal, nameVal, codeVal, valueVal) => {
    let errors = [];
    if (!typeVal && typeVal === "") {
      let typeerror = "Please Enter Type";
      errors.push(typeerror);
    }
    if (!nameVal && nameVal === "") {
      let nameerror = "Please Enter Name";
      errors.push(nameerror);
    }
    if (!codeVal && codeVal === "") {
      let codeerror = "Please Enter Code";
      errors.push(codeerror);
    }
    if (!valueVal && valueVal === "") {
      let valueerror = "Please Enter Value";
      errors.push(valueerror);
    }
    if (valueVal > 100) {
      let valueerror = "Value cannot be greater than 100";
      errors.push(valueerror);
    }
    if (errors.length > 0) {
      return errors;
    }
    return;
  };

  const cancelHandler = () => {
    setTypeOfModal("");
    setModalVisible(false);
    setSelectedTax("");
  };

  const okHandler = () => {
    setConfirmLoading(true);

    const errors = validateInputs(type, name, code, value);

    const taxObject = {
      name: name !== -1 ? name : newName,
      type: type !== -1 ? type : newType,
      code: code,
      value: value,
    };
    if (errors) {
      setErrors(errors);
      errors.map((error) => message.error(`${error}`));
    }
    if (!errors) {
      createTax(taxObject);
      setModalVisible(false);
    }
    setTypeOfModal("");
    setConfirmLoading(false);
    setSelectedTax("");
  };

  const createTax = async (taxObject) => {
    try {
      if (typeOfModal === "edit") {
        taxObject["id"] = selectedTax?.id;
      }
      await axios.post(
        "/api/tax/" + (typeOfModal === "edit" ? "edit" : "create"),
        taxObject
      );
      message.success(
        typeOfModal === "edit"
          ? "Tax Updated Successfully"
          : "Tax Created Successfully"
      );
      getTaxes();
    } catch (err) {
      message.error("Error Updating Tax");
    }
  };

  useEffect(() => {
    const mapperFn = (arr = []) => {
      const sth = arr.map((el) => ({
        name: el,
        value: el,
      }));
      // sth.push(othersObject);
      return sth;
    };
    nameoptions = mapperFn(taxNames);
    typeoptions = mapperFn(taxTypes);
    getTaxes();
  }, []);

  useEffect(() => {
    if (typeOfModal === "edit") {
      setCode(selectedTax.code);
      setValue(selectedTax.value);
      setName(selectedTax?.name);
      setType(selectedTax?.type);
    }
  }, [typeOfModal, selectedTax]);

  const onNameSelect = (value) => {
    setName(value);
  };

  const onTypeSelect = (value) => {
    setType(value);
  };

  const searchResult = (query) =>
    new Array()
      .join(".")
      .split(".")
      .map((_, idx) => {
        const category = `${query}`;
        return {
          value: category,
        };
      });

  const handleSearch = (value) => {
    setResultOptions(value ? searchResult(value) : []);
  };

  return (
    <Modal
      title={typeOfModal === "edit" ? "Edit Tax" : "Add Tax"}
      visible={isModalVisible}
      onOk={okHandler}
      onCancel={cancelHandler}
      confirmLoading={confirmLoading}
      footer={[
        <Button key="back" onClick={cancelHandler}>
          Cancel
        </Button>,
        <Button onClick={okHandler} type="primary" htmlType="submit">
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Row>
          <Col>
            <Form.Item label="Code" required tooltip="e.g., 1122">
              <Input
                value={code}
                type="number"
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter Code Here"
              />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Type" required tooltip="e.g., GST">
              <CustomAutoComplete
                value={type}
                onSelect={onTypeSelect}
                newOption={newType}
                setNewOption={setNewType}
                options={typeoptions}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Name" required tooltip="e.g., HSN">
          <CustomAutoComplete
            value={name}
            onSelect={onNameSelect}
            newOption={newName}
            setNewOption={setNewName}
            options={nameoptions}
          />
        </Form.Item>

        <Form.Item label="Value" required tooltip="e.g., 5">
          <Input
            value={value}
            type="number"
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter Value Here"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

const CustomAutoComplete = ({
  value,
  options,
  onSelect,
  newOption,
  setNewOption,
}) => {
  return (
    <Row>
      <Col>
        {" "}
        <Select
          style={{
            minWidth: "120px",
          }}
          showSearch
          value={value}
          placeholder="Select 'others' if tax not found"
          optionFilterProp="children"
          onSelect={onSelect}
          onSearch={() => {}}
          filterOption={filterOptionWithGroups}
        >
          <Select.OptGroup label="Create a new">
            <Select.Option value={-1}>Create</Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Choose">
            {options.map(({ value, name }) => {
              return <Select.Option value={value}>{name}</Select.Option>;
            })}
          </Select.OptGroup>
        </Select>
      </Col>
      {value === -1 && (
        <Col style={{ width: "130px" }}>
          <Input
            placeholder="Enter new entry"
            value={newOption}
            onChange={(e) => setNewOption(e.target.value)}
          />
        </Col>
      )}
    </Row>
  );
};

const mapState = (state) => {
  return {
    taxTypes: state?.data?.taxes?.types || [],
    taxNames: state?.data?.taxes?.names || [],
  };
};

const mapDispatch = {
  getTaxes: () => getList("taxes"),
};

export default connect(mapState, mapDispatch)(CreateUpdateTaxModal);
