import { useEffect, useState } from "react";

import useTableSearch from "../../../common/hooks/useTableSearch";
import { Button, Select, Table, Tooltip } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getList } from "../../../actions/dataActions";
import TermsModal from "./TermsModal";

const Terms = ({ getPaymentTerms, paymentTerms }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [prvType, setPrvType] = useState("");
  const [prvName, setPrvName] = useState("");
  const [prvId, setPrvId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const showModal = (value, record) => {
    if (value === "edit") {
      setPrvType(record?.type);
      setPrvName(record?.name);
      setPrvId(record?.id);
      setIsEdit(true);
    } else {
      setPrvType("");
      setPrvName("");
      setPrvId("");
      setIsEdit(false);
    }
    setModalVisible(true);
  };

  useEffect(() => {
    getPaymentTerms();
  }, []);

  const termsCols = [
    {
      title: "Name",
      width: 300,
      dataIndex: "name",
      ...useTableSearch("name"),
    },
    {
      title: "Type",
      width: 150,
      dataIndex: "type",
      defaultSortOrder: "descend",
      ...useTableSearch("type"),
    },
    {
      title: "Actions",
      width: 150,
      render: (value, record) => {
        return (
          <div className="flex">
            <Tooltip title="Edit">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => showModal("edit", record)}
                danger
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Button onClick={() => showModal("add")} type="primary">
        <PlusOutlined /> Add Terms
      </Button>

      <Table
        style={{
          maxWidth: "800px",
        }}
        columns={termsCols}
        dataSource={paymentTerms}
        rowKey="id"
      />

      {isModalVisible && (
        <TermsModal
          setModalVisible={setModalVisible}
          isModalVisible={isModalVisible}
          prvType={prvType}
          prvName={prvName}
          prvID={prvId}
          isEdit={isEdit}
          getPaymentTerms={getPaymentTerms}
        />
      )}
    </>
  );
};

const mapState = (state) => {
  return {
    paymentTerms: state.data.paymentTerms,
  };
};

const mapDispatch = {
  getPaymentTerms: () => getList("paymentTerms"),
};

export default connect(mapState, mapDispatch)(Terms);
