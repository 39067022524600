import {
  Row,
  Col,
  Select,
  Button,
  Form,
  Modal,
  Divider,
  DatePicker,
  Input,
  Checkbox,
  Table,
  Popconfirm,
  message,
} from "antd";
import { useMemo, useRef, useState } from "react";
import { Formik, useFormik } from "formik";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as yup from "yup";
import {
  DELIVERY_STATUSES,
  DELIVERY_TERMS,
  PAYMENT_TERMS,
} from "../../../assets/constants";
import getAddress from "../../../common/functions/getAddress";
import { item_types, skuType } from "./itemTypes";
import getDataFromPincodeHandler from "../../partners/utils";
import axios from "axios";
import {
  getCustomersWithCallback,
  getList,
} from "../../../actions/dataActions";
import usePrevious from "../../../common/hooks/usePrevious";
import { roundValue } from "../../../common/functions/roundValue";

// const DATE_FORMAT = "DD-MM-YYYY HH:mm:ss";

const SalesOrderSchema = yup.object().shape({
  customer_id: yup.string().required("Please enter a Customer Name"),
  customer_address: yup.string().required(),
  consignee: yup.string(),
  consignee_address: yup.string(),
  created_date: yup.string().required("Please select a created date"),
  due_date: yup.string().required("Please select due date"),
  delivery_terms: yup.string(),
  delivery_status: yup
    .mixed()
    .required("Please enter a delivery status")
    .oneOf(
      ["Not Dispatched", "Dispatched", "Delivered"],
      "Please select appropriate delivery status"
    ),
  total_amount: yup.number(),
  payment_terms: yup.string().required("Please select the Payment Terms"),
  comments: yup.mixed(),
  transporter: yup.string().nullable(),
  transport_cost: yup.number(),
  track_code: yup.mixed(),
  is_sample: yup.boolean().required("This is required field"),
  items: yup
    .array()
    .of(
      yup.object().shape({
        item_id: yup.number().required("Select a type (Material / SKU)"),
        quantity: yup.number().required("Quantity used cannot be empty").moreThan(0, "Quantity used cannot be zero or less than zero"),
        rate: yup.number().required("Rate used cannot be empty").moreThan(0, "Rate used cannot be zero or less than zero"),
        amount: yup.number().required("Amount used cannot be empty"),
      })
    )
    .required("Please add atleast one Item"),
  // .test((items) =>
  //   !items.length ||
  //   items.some(
  //     ({ item_id, item_name, quantity }) =>
  //       !item_id || !item_name || !quantity
  //   )
  //     ? "Incomplete Details of Item"
  //     : ""
  // ),
});

const EMPTY_RECIPE_FORMAT = {
  item_id: "",
  item_type: "",
  item_name: "",
  quantity: "",
  rate: "",
  discount: 0,
  tax: 0,
  amount: "",
  uom: "",
};

const filterOption = (input, option) =>
  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const SalesOrderForm = ({
  locationId,
  customers,
  paymentTerms,
  skus,
  materials,
  transporters,
  so,
  onCancel,
  onOk,
  onDelete,
  customFn,
  getCustomers,
  itemPrices,
}) => {
  const getInitialValues = () => {
    if (so?.id) {
      const {
        created_date,
        due_date,
        location_id,
        order_status,
        is_sample,
        door_delivery,
        delivery_terms,
        customer_id,
        customer_address,
        consignee_id,
        consignee_address,
        payment_terms,
        delivery_status,
        comments,
        transporter,
        track_code,
        transport_cost,
        items,
        total_amount,
        channel,
        source,
      } = so;
      return {
        created_date,
        due_date,
        location_id,
        order_status,
        is_sample,
        delivery_terms,
        door_delivery,
        customer_id,
        customer_address,
        consignee_id,
        consignee_address,
        payment_terms,
        delivery_status,
        comments,
        transporter: transporter.id,
        track_code,
        transport_cost,
        items,
        total_amount,
        channel,
        source,
      };
    }
    return {
      created_date: moment().toISOString(),
      due_date: moment().toISOString(),
      location_id: locationId,
      order_status: "Approved",
      is_sample: true,
      door_delivery: true,
      customer_id: "", // the customer id
      customer_address: "", //the customer address id
      consignee_id: "", // the consignee id
      consignee_address: "", // the consignee address id
      payment_terms: "",
      delivery_terms: "",
      delivery_status: DELIVERY_STATUSES[0].value || "Not Dispatched",
      comments: "",
      transporter: null,
      track_code: "",
      transport_cost: "",
      items: [EMPTY_RECIPE_FORMAT],
      total_amount: 0,
      channel: "",
      source: "AgroTrust Invento",
      customer_address_list: [],
      // autofill
      to_fill: false,
    };
  };

  const addToItems = (values, setFieldValue) => {
    let _items = [...values.items];
    _items.push({
      item_id: "",
      item_type: "",
      item_name: "",
      quantity: "",
      rate: "",
      tax: 0,
      amount: "",
      uom: "",
    });
    setFieldValue("items", _items);
  };

  const submitDetails = (args) => {
    let finalValues = values;
    // finalValues["payment_terms"] =
    //   paymentTerms?.find((el) => el.id === values.payment_terms)?.name || "";
    finalValues.transport_cost = values.transport_cost || 0;
    delete finalValues.customer_address_list;
    if (so?.id) finalValues.id = so.id;
    onOk(finalValues);
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: SalesOrderSchema,
    // onSubmit: () => console.log("runnnn"),
    onSubmit: submitDetails,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = formik;

  const recipeErrors = useMemo(() => {
    const err = (errors.items || []).find((el) => el);
    if (err?.item_id) return <div>You must select an item</div>;
    else if (err?.quantity) return <div>{err?.quantity}</div>;
    else if (err?.rate) return <div>{err?.rate}</div>;
    return <div></div>;
  }, [errors.items]);

  const prevCustomer = usePrevious(values.customer_id);

  const [activeCustomersList, setActiveCustomersList] = useState([]);

  useEffect(() => {
    let temp = customers?.filter((el) => el.status === "active");
    temp = temp.map((el) => ({
      ...el,
      numbers: el?.addresses?.map((ad) => ad?.contact).join(" / "),
    }));
    setActiveCustomersList(temp);
  }, [customers]);

  useEffect(() => {
    const customer = customers?.find((el) => el.id === values.customer_id);
    if (customer?.id) setFieldValue("channel", customer?.type);
    const customerAddr = customer?.addresses;
    setFieldValue("customer_address_list", customerAddr);
    if (prevCustomer) {
      setFieldValue("consignee_id", values.customer_id);
      // is trying to change the customer - load the first address in customer and consignee addresses by default
      setFieldValue("customer_address", customerAddr?.[0]?.id);
      setFieldValue("consignee_address", customerAddr?.[0]?.id);
    } else {
      if (so?.id) {
        setFieldValue("customer_address", so?.customer_address?.id);
      } else {
        setFieldValue("consignee_id", values.customer_id);
        setFieldValue("consignee_address", customerAddr?.[0]?.id);
        setFieldValue("customer_address", customerAddr?.[0]?.id);
      }
    }
    //eslint-disable-next-line
  }, [values.customer_id]);

  useEffect(() => {
    if (!prevCustomer && so?.id) {
      setFieldValue("consignee_address", so?.consignee_address?.id);
    }
    //eslint-disable-next-line
  }, [values.consignee_id]);

  const calculateTotalAmount = () => {
    let tempAmount =
      parseFloat(
        values.items.reduce((a, b) => {
          return a + (b.amount || 0);
        }, 0)
      ) + parseFloat(values.transport_cost || 0);

    setFieldValue("total_amount", tempAmount);
  };

  useEffect(() => {
    calculateTotalAmount();
    //eslint-disable-next-line
  }, [values.items, values.transport_cost]);

  const removeSkuFromTable = (index) => {
    let _temp = [...values.items];
    _temp.splice(index, 1);
    setFieldValue("items", _temp);
  };

  const populatePrice = async (id, type) => {
    let url = `/api/price?item_type=${type}&item_id=${id}`;
    try {
      const res = await axios.get(url);

      if (res.status === 200) {
        let price = res.data[0]?.value;

        return price;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const itemColumns = [
    {
      title: "Item ID",
      dataIndex: "item_id",
      width: 100,
      render: (value, record) => {
        return <Input value={value} disabled />;
      },
    },
    {
      title: "Item Type",
      dataIndex: "item_type",
      render: (value, record, index) => {
        const handleItemTypeChange = (value) => {
          let _temp = [...values.items];
          _temp[index] = {
            ..._temp[index],
            ...EMPTY_RECIPE_FORMAT,
            item_type: value,
          };
          setFieldValue("items", _temp);
        };
        return (
          <Select
            style={{ width: 150 }}
            value={value}
            onChange={handleItemTypeChange}
          >
            {item_types.map(({ type }) => (
              <Select.Option labelInValue key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Item Name",
      dataIndex: "item_id",
      render: (value, record, index) => {
        const current = values.items[index];
        let dataSource =
          current?.item_type?.toLowerCase() === skuType ? skus : materials;
        // index will refer to the index of the element in recipeDataArray

        const handleItemChange = async (id) => {
          let _temp = [...values.items];
          // find the item in the proper array
          const item = dataSource.find((el) => el.id === id);

          const price = await populatePrice(id, current?.item_type);

          // once item is found, update the blank / previous item with the item details
          _temp[index] = {
            ..._temp[index],
            item_id: item?.id,
            item_name: item?.product_name || item?.name,
            quantity: 0,
            uom: item?.uom,
            rate: price || 0,
            tax: item?.tax,
            amount: item?.amount || 0,
          };
          setFieldValue("items", _temp);
        };

        return (
          <Select
            disabled={!current?.item_type}
            showSearch
            filterOption={filterOption}
            style={{
              width: 250,
            }}
            value={value}
            onChange={handleItemChange}
          >
            {dataSource?.map((item, i) => {
              return (
                <Select.Option labelInValue key={item.id} value={item.id}>
                  {item?.sku || item?.name}
                </Select.Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: "Quantity",
      render: (value, record, index) => {
        //Making suffix in quantity change dynamic
        
      const current = values.items[index];
      let dataSource =
      current?.item_type?.toLowerCase() === skuType ? skus : materials;
      const item = dataSource.find((el) => el.id === current?.item_id);
      const suffix = current?.item_type?.toLowerCase() === "sku"
       ? "Pc"
       : item?.uom || "Pc";
      
      

        const handleQtyChange = (e) => {
          let curr = { ...values.items[index] };
          curr.quantity = e.target.value; 

          curr.amount = !curr.rate
            ? 0
            : curr.quantity *
              curr.rate *
              (1 + curr.tax / 100) *
              (1 - curr.discount / 100);
          let _temp = [...values.items];
          _temp[index] = curr;

          setFieldValue("items", _temp);
        };

        return (
          <Input
            value={record.quantity}
            suffix={suffix}
            min={1}
            onChange={handleQtyChange}
          />
        );
      },
    },
    {
      title: "Rate per pc (inc. of taxes)",
      render: (value, record, index) => {
        const handleRateChange = (e) => {
          let curr = { ...values.items[index] };
          curr.rate = e.target.value;
          curr.amount = roundValue(
            curr.quantity *
              e.target.value *
              (1 - curr.discount / 100) *
              (1 + curr.tax / 100)
          );
          let _temp = [...values.items];
          _temp[index] = curr;
          setFieldValue("items", _temp);
        };

        // }
        return (
          <Input
            suffix="INR"
            value={record?.rate}
            onChange={handleRateChange}
          />
        );
      },
    },
    {
      title: "Tax",
      dataIndex: "tax",
      render: (value, record, index) => {
        return <Input disabled value={value} suffix="%" />;
      },
    },
    {
      title: "Discount",
      dataIndex: "discount",
      render: (value, record, index) => {
        const handleDiscountChange = (e) => {
          let _temp = [...values.items];
          _temp[index] = {
            ..._temp[index],
            discount: e.target.value,
            amount: !_temp[index].quantity
              ? 0
              : parseFloat(
                  _temp[index].quantity *
                    _temp[index].rate *
                    (1 + _temp[index].tax / 100) *
                    (1 - e.target.value / 100)
                ).toFixed(2),
          };
          setFieldValue("items", _temp);
        };
        return (
          <Input
            disabled={!record.quantity}
            onChange={handleDiscountChange}
            value={value}
            suffix="%"
          />
        );
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      render: (value, record, index) => {
        const handleAmtChange = (e) => {
          let _temp = [...values.items];
          _temp[index] = {
            ..._temp[index],
            // amount: e.target.value,
            quantity: _temp[index].quantity,
            amount: roundValue(
              e.target.value /
                (_temp[index].quantity *
                  (1 + _temp[index].tax / 100) *
                  (1 - _temp[index].discount / 100))
            ),
          };

          setFieldValue("items", _temp);
        };
        return (
          <Input
            suffix="INR"
            disabled={!record.quantity}
            onChange={handleAmtChange}
            value={roundValue(record.amount)}
            // disabled
          />
        );
      },
    },
    {
      title: "Remove",
      render: (value, record, index) => {
        const isLastItem = values.items.length === 1;
        return (
          <Button
            type="primary"
            onClick={() => {
              removeSkuFromTable(index);
            }}
            disabled={isLastItem}
          >
            X
          </Button>
        );
      },
    },
  ];

  const [showCreateCustomerFields, setShowCreateCustomerFields] =
    useState(false);

  const [customerName, setCustomerName] = useState("");
  const [tempCustName, setTempCustName] = useState("");
  const [customerPincode, setCustomerPincode] = useState("");
  const [customerContact, setCustomerContact] = useState("");
  const [customerState, setCustomerState] = useState("");

  // to fill status
  const [toFill, setToFill] = useState(false);

  // console.log(toFill, "toFill")

  const getDataFromPincode = async (value) => {
    const temp = await getDataFromPincodeHandler(value);
    setCustomerState(temp.State);
  };

  let data = [];

  const createNewCustomer = async () => {
    let finalObject = {
      name: customerName,
      type: "B2C",
      role: "customer",
      addresses: [
        {
          state: customerState,
          contact: customerContact,
        },
      ],
      poc: [],

      status: "active",
    };

    try {
      const url = `/api/partners/partner/create`;
      const response = await axios.post(url, finalObject);
      if (response.status === 200) {
        let tempId = response.data.id;
        customFn(tempId, setFieldValue);
        setShowCreateCustomerFields(false);
        setCustomerPincode("");
        setCustomerState("");
      }
    } catch (error) {
      window.alert(error);
    }
  };

  const [deletePopup, setDeletePopup] = useState(false);

  const showPopconfirm = () => {
    setDeletePopup(true);
  };

  const handleFillStatusChange = () => {
    setFieldValue("to_fill", !values.to_fill);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {/* {JSON.stringify(so, null, 2)} */}
        <Row gutter={0}>
          {/* Customer Name */}
          <Col span={4} offset={0}>
            <p>Customer Name*</p>
            <Select
              showSearch
              onSearch={setTempCustName}
              notFoundContent={
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      setCustomerName(tempCustName);
                      setShowCreateCustomerFields(true);
                    }}
                  >
                    Create New Customer
                  </Button>
                </>
              }
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.customer_id}
              onChange={(id) => {
                setFieldValue("customer_id", id);
              }}
              onBlur={handleBlur("customer_id")}
            >
              {activeCustomersList.map((_cus) => (
                <Select.Option key={_cus.id} value={_cus.id}>
                  {`${_cus.name} / ${_cus.numbers || ""}`}
                </Select.Option>
              ))}
            </Select>

            {
              <Modal
                destroyOnClose={true}
                visible={showCreateCustomerFields}
                onOk={createNewCustomer}
                onCancel={() => setShowCreateCustomerFields(false)}
              >
                <p>Create a Customer</p>

                <Input
                  style={{ margin: "0.5rem" }}
                  value={customerName}
                  placeholder="Customer Name(required)"
                  onChange={(e) => setCustomerName(e.target.value)}
                />

                <Input
                  value={customerContact}
                  style={{ margin: "0.5rem" }}
                  type={"number"}
                  placeholder="Contact(required)"
                  onChange={(e) => setCustomerContact(e.target.value)}
                />
              </Modal>
            }

            {getErrorMsg(touched.customer_id, errors.customer_id)}
          </Col>
          {/* Customer Address */}
          <Col span={6} offset={1}>
            <p>Customer Address*</p>
            <Select
              placeholder="Select a customer then an address"
              disabled={!values.customer_id}
              showSearch
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.customer_address}
              onChange={(val) => {
                setFieldValue("customer_address", val);
              }}
              onBlur={handleBlur("customer_address")}
            >
              {values.customer_address_list?.map(({ id, ...rest }) => (
                <Select.Option key={id} value={id}>
                  {getAddress(rest)}
                </Select.Option>
              ))}
            </Select>
            {/* <Input placeholder="Select a customer then an address"/> */}
            {getErrorMsg(touched.customer_address, errors.customer_address)}
          </Col>
          <Divider type="vertical" />
          {/* Consignee */}
          <Col span={4} offset={1}>
            <p>Consignee</p>
            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.consignee_id}
              onChange={(val) => {
                setFieldValue("consignee_id", val);
              }}
              onBlur={handleBlur("consignee_id")}
            >
              {customers?.map((_cus) => (
                <Select.Option key={_cus.id} value={_cus.id}>
                  {_cus.name}
                </Select.Option>
              ))}
            </Select>
            {getErrorMsg(touched.consignee_id, errors.consignee_id)}
          </Col>
          {/* Consignee Address */}
          <Col span={6} offset={1}>
            <p>Consignee Address</p>
            <Select
              disabled={!values.consignee_id}
              placeholder="Select a consignee then an address"
              showSearch
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.consignee_address}
              onChange={(val) => {
                setFieldValue("consignee_address", val);
              }}
              onBlur={handleBlur("consignee_address")}
            >
              {values.customer_address_list?.map(({ id, ...rest }) => (
                <Select.Option key={id} value={id}>
                  {getAddress(rest)}
                </Select.Option>
              ))}
            </Select>
            {getErrorMsg(touched.consignee_address, errors.consignee_address)}
          </Col>
        </Row>

        <Row className="mt-2">
          {/* Created Date */}
          <Col span={4}>
            <p>Created Date*</p>
            <DatePicker
              showTime
              allowClear={false}
              style={{ width: "100%" }}
              value={moment(values.created_date)}
              onChange={(dt, dtstr) => {
                setFieldValue("created_date", moment(dtstr).toISOString());
              }}
            />
            {getErrorMsg(touched.created_date, errors.created_date)}
          </Col>
          {/* Due Date */}
          <Col span={4} offset={1}>
            <p>Due Date*</p>
            <DatePicker
              showTime
              allowClear={false}
              style={{ width: "100%" }}
              value={moment(values.due_date)}
              onChange={(dt, dtstr) => {
                setFieldValue("due_date", moment(dtstr).toISOString());
              }}
            />
          </Col>
          {/* Delivery Terms */}
          <Col span={6} offset={1}>
            <div style={{ display: "flex" }}>
              <p>Delivery Terms</p>
            </div>

            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.delivery_terms}
              onChange={(termId) => {
                const selectedTerm = paymentTerms.find(
                  ({ id }) => id === termId
                );
                if (selectedTerm) {
                  setFieldValue("delivery_terms", selectedTerm.name);
                }
              }}
            >
              {paymentTerms
                .filter(({ type }) => type === "Delivery")
                .map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
            {getErrorMsg(touched.delivery_terms, errors.delivery_terms)}
          </Col>
          {/* Delivery Status */}
          <Col offset={1}>
            <p>Delivery Status*</p>
            <Select
              showSearch
              style={{ width: 300 }}
              value={values.delivery_status}
              onChange={(val) => {
                setFieldValue("delivery_status", val);
              }}
            >
              {DELIVERY_STATUSES.map(({ text, value }) => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
            {getErrorMsg(touched.delivery_status, errors.delivery_status)}
          </Col>
        </Row>
        <br />
        <Row gutter={0}>
          {/* Total Amount */}
          <Col span={6} offset={0}>
            <p>Total Amount*</p>
            <Input value={roundValue(values.total_amount)} disabled />
          </Col>
          {/*  Payment Terms*/}
          <Col span={6} offset={1}>
            <div style={{ display: "flex" }}>
              <p>Payment Terms*</p>
            </div>
            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.payment_terms}
              onChange={(term) => {
                const termString = paymentTerms?.find(
                  (el) => el.id === term
                )?.name;
                setFieldValue("payment_terms", termString, true);
              }}
            >
              {paymentTerms
                .filter(({ type }) => type === "Payment")
                .map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
            {getErrorMsg(touched.payment_terms, errors.payment_terms)}
          </Col>
          {/* Comments */}
          <Col span={5} offset={1}>
            <p>Comments</p>
            <Input.TextArea
              value={values.comments}
              onChange={handleChange("comments")}
              autoSize
            />
          </Col>
        </Row>

        <Row className="mt-2">
          {/* Transporter */}
          <Col span={5} offset={0}>
            <p>Transporter</p>
            <Select
              allowClear
              showSearch
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.transporter}
              onChange={(v) => {
                setFieldValue("transporter", v);
              }}
            >
              {transporters
                ?.filter((e) => e.status === "active")
                .map((i) => {
                  return (
                    <Select.Option key={i.id} value={i.id}>
                      {i.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Col>

          {/* Transporter Cost */}
          <Col span={5} offset={1}>
            <div style={{ display: "flex" }}>
              <p>Transporter Cost</p>
            </div>
            <Input
              type="number"
              value={values.transport_cost}
              onChange={handleChange("transport_cost")}
            />
          </Col>

          {/* Track Code */}
          <Col span={4} offset={1}>
            <p>Track Code</p>
            <Input
              value={values.track_code}
              onChange={handleChange("track_code")}
            />
          </Col>

          {/* Sample */}
          <Col span={3} offset={1}>
            <div style={{ display: "flex" }}>
              <p>Sample</p>
            </div>
            <Checkbox
              checked={values.is_sample}
              onChange={() => setFieldValue("is_sample", !values.is_sample)}
            >
              This is a sample
            </Checkbox>
          </Col>

          {/* Autofill the status */}

          {so?.id ? (
            <Col></Col>
          ) : (
            <Col span={3} offset={1}>
              <div style={{ display: "flex" }}>
                <p>Autofill</p>
              </div>
              <Checkbox
                checked={values.to_fill}
                onChange={handleFillStatusChange}
              >
                Auto fill status
              </Checkbox>
            </Col>
          )}
        </Row>

        <br />

        <Row gutter={0}>
          <Col span={12} offset={0}>
            <p>SKU Items</p>
          </Col>

          <Col offset={3} span={4} style={{ textAlign: "right" }}>
            <p>
              Total Amount:{" "}
              {isNaN(values.total_amount) ? 0 : roundValue(values.total_amount)}
            </p>
          </Col>

          <Col offset={2}>
            <Button
              type="primary"
              onClick={() => addToItems(values, setFieldValue)}
            >
              + Add{" "}
            </Button>
          </Col>
        </Row>

        <Table
          columns={itemColumns}
          dataSource={values.items}
          pagination={false}
        />

        <Divider className="mt-0.5 mb-0.5" />
        <div>{recipeErrors}</div>
        {/* <div>{isSubmitting && JSON.stringify(errors)}</div> */}

        <div className="flex justify-between">
          <div>
            {so?.fill_status === "NA" && (
              <Popconfirm
                title="Do you want to Delete this order?"
                placement="right"
                okText="Delete"
                cancelText="cancel"
                onConfirm={() => onDelete(so?.id)}
                onCancel={() => setDeletePopup(false)}
              >
                <Button
                  type="danger"
                  style={{ marginRight: 10 }}
                  onClick={showPopconfirm}
                >
                  Delete Order
                </Button>
              </Popconfirm>
            )}
          </div>
          <div>
            <Button className="mr-0.5" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {so?.id ? "Update Order" : "Create Order"}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const getErrorMsg = (touched, error) => {
  if (touched && error) return error;
  return "";
};

const mapState = (state) => ({
  skus: state.data.skus,
  materials: state.data.materials,
  customers: state.data.customers,
  transporters: state.data.transporters,
  paymentTerms: state.data.paymentTerms,
  itemPrices: state.data.itemPrices,
});

const mapDispatch = {
  customFn: getCustomersWithCallback,
  getCustomers: () => getList("customers"),
  getPaymentTerms: () => getList("paymentTerms"),
  getItemPrices: () => getList("itemPrices"),
};

export default connect(mapState, mapDispatch)(SalesOrderForm);
