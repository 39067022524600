import { useState, useEffect, memo, useMemo } from "react";
import {
  Table,
  Switch,
  Button,
  Row,
  Col,
  Input,
  Select,
  Divider,
  Form,
  message,
} from "antd";
import { UOMS } from "../../../assets/constants";

function SkusCreateUpdateForm({
  values,
  hsnList,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleBlur,
  handleSubmit,
  prepareForOk,
  selectedSku,
  materials,
  onCancel,
}) {
  const [hsnId, setHsnId] = useState(null);

  const weightUpdater = () => {
    switch (values.uom) {
      case "kg":
        setFieldValue("weight", values.quantity);
        break;
      case "g":
        setFieldValue("weight", values.quantity / 1000);
        break;
      default:
        setFieldValue("weight", "");
        break;
    }
  };

  useEffect(() => {
    if (values.quantity) {
      weightUpdater();
    }

    //eslint-disable-next-line
  }, [values.uom]);

  useEffect(() => {
    weightUpdater();
    //eslint-disable-next-line
  }, [values.quantity]);

  const recipeColumns = [
    {
      title: "Material ID",
      render: (value, record) => {
        return <Input value={record.material_id} disabled />;
      },
    },
    {
      title: "Material Name",
      width: "70%",
      render: (value, record, index) => {
        return (
          <>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              value={
                record.name ||
                materials?.find(
                  (el) => el?.id === values.recipe[index]?.material_id
                )?.name
              }
              onChange={(materialId) => {
                // if material id is already present in recipe, then do not add
                if (values?.recipe?.find((el) => el.material_id === materialId)) {
                  message.error("Material already present in recipe");
                  // delete entry in last index of values.recipe
                  let _recipes = [...values.recipe];
                  if(_recipes.length === 1) return;
                  _recipes.pop();
                  setFieldValue("recipe", _recipes);

                  return;
                }
              
                let _recipe = [...values.recipe];
                const { name, uom } = materials.find(
                  (m) => m.id === materialId
                );
                
                
                _recipe[index] = {
                  ..._recipe[index],
                  material_id: materialId,
                  material_name: name,
                  uom,
                };

                setFieldValue("recipe", _recipe);
              }}
            >
              {materials.map((m) => {
                return (
                  <Select.Option key={m.id} value={m.id}>
                    {m.name}
                  </Select.Option>
                );
              })}
            </Select>
          </>
        );
      },
    },
    {
      title: "Quantity used",
      width: 200,
      render: (value, record, index) => {
        return (
          <Input
            value={record.weight}
            // suffix={record?.uom}
            suffix={record.uom}
            onChange={(e) => {
              let _recipe = [...values.recipe];
              _recipe[index] = {
                ..._recipe[index],
                weight: e.target.value,
              };
              setFieldValue("recipe", _recipe);
            }}
          />
        );
      },
    },
    {
      title: "Remove",
      render: (value, record, index) => {
        return (
          <Button type="primary" onClick={() => removeFromRecipes(index)}>
            X
          </Button>
        );
      },
    },
  ];

  const addToRecipes = () => {
    let _recipes = [...values.recipe];
    _recipes.push({
      material_id: null,
      weight: "",
      material_name: "",
      uom: "",
    });
    setFieldValue("recipe", _recipes);
  };

  const removeFromRecipes = (index) => {
    try{
      if(values.recipe.length === 1) {
        message.error("Atleast one recipe is required");
        return;
      }
      let _recipes = [...values.recipe];
      _recipes.splice(index, 1);
      setFieldValue("recipe", _recipes);
    }catch(e) {
      console.error(e);
    }

  };

  const recipeErrors = useMemo(() => {
    const err = (errors.recipe || []).find((el) => el);
    if (err?.material_id) return <div>You must enter a material</div>;
    else if (err?.weight) return <div>{err?.weight}</div>;
    return <div></div>;
  }, [errors.recipe]);

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={0}>
        <Col span={8} offset={0}>
          <Form.Item
          label="Product Name"
            validateStatus={
              touched.product_name && errors.product_name ? "error" : ""
            }
            style={{display: "block"}}
            required
          >
            <Input
              placeholder="e.g, Bhendi Polybag"
              value={values.product_name}
              onBlur={handleBlur("product_name")}
              onChange={handleChange("product_name")}
            />
          </Form.Item>
        </Col>
        <Col span={7} offset={2}>
          <Form.Item
            validateStatus={touched.display && errors.display ? "error" : ""}
            label="Display Name"
            required
          >
            <div style={{ display: "flex" }}>
              <Input
                placeholder="e.g, Bhendi Polybag"
                value={values.display}
                onChange={handleChange("display")}
                onBlur={handleBlur("display")}
              />
              <Button
                type="button"
                onClick={() => setFieldValue("display", values.product_name)}
              >
                Same as Product Name
              </Button>
            </div>
          </Form.Item>
        </Col>
        <Col span={3} className="justify-center flex flex-col" offset={1}>
          <Form.Item
            validateStatus={touched.status && errors.status ? "error" : ""}
            label="Sku status"
            style={{display: "block"}}
            required
          >
            <Switch
              checked={values.status === "active"}
              onChange={(checked) =>
                checked
                  ? setFieldValue("status", "active")
                  : setFieldValue("status", "inactive")
              }
            />
            &nbsp;
            {values.status === "active" ? "Active" : "Inactive"}
          </Form.Item>
        </Col>
      </Row>
      <br />

      <Row gutter={0}>
        <Col span={3} offset={0}>
          <Form.Item
            validateStatus={touched.quantity && errors.quantity ? "error" : ""}
            label="Quantity"
            required
          >
            <Input
              type="number"
              value={values.quantity}
              onBlur={handleBlur("quantity")}
              onChange={handleChange("quantity")}
            />
          </Form.Item>
        </Col>
        <Col span={3} offset={1}>
          <Form.Item
            validateStatus={touched.uom && errors.uom ? "error" : ""}
            label="UoM"
            required
            style={{display: "block"}}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              value={values.uom}
              onChange={handleChange("uom")}
              onBlur={handleBlur("uom")}
            >
              {UOMS.map((i) => {
                return (
                  <Select.Option key={i.text} value={i.value}>
                    {i.text}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3} offset={1}>
          <Form.Item
            validateStatus={touched.weight && errors.weight ? "error" : ""}
            label="Equivalent Weight"
            required
          >
            <Input
              value={values.weight}
              suffix={"Kg"}
              onBlur={handleBlur("weight")}
              onChange={handleChange("weight")}
              type="number"
             
            />
          </Form.Item>
        </Col>
        <Col span={4} offset={1}>
          <Form.Item
            validateStatus={touched.barcode && errors.barcode ? "error" : ""}
            label="Barcode"
          >
            <Input
              value={values?.barcode}
              onBlur={handleBlur("barcode")}
              onChange={handleChange("barcode")}
            />
          </Form.Item>
        </Col>
      </Row>
      <br />

      <Row gutter={0}>
        <Col span={6} offset={0}>
          <div style={{ display: "flex" }}>
            <p>Full SKU Name</p>
          </div>
          <Input
            value={`${values.product_name || ""} / ${values.quantity || ""} ${
              values.uom || ""
            }`}
            disabled
          />
        </Col>
        <Col span={4} offset={1}>
          <Form.Item
            validateStatus={
              touched.expiry && errors.expiry ? "error" : ""
            }
            help={errors.expiry}
            label="Expiry (in days)"
          >
            <Input
              value={values.expiry}
              onBlur={handleBlur("expiry")}
              onChange={handleChange("expiry")}
            />
          </Form.Item>
        </Col>

        <Col span={5} offset={2}>
          <Form.Item
            validateStatus={touched.hsnId && errors.hsnId ? "error" : ""}
            label="HSN Code"
            style={{display: "block"}}
          >
            <div>
            <Select
              showSearch
              allowClear
              placeholder="Select HSN from the list"
              style={{ width: "100%" }}
              value={hsnId}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(index) => {
                setFieldValue("tax", hsnList[index]?.value);
                setHsnId(index);
              }}
              onBlur={handleBlur("hsnId")}
            >
              {hsnList?.map((hsn, index) => {
                return (
                  <Select.Option key={index} value={index}>
                    {`${hsn.code}`}
                  </Select.Option>
                );
              })}
            </Select>
            </div>
          </Form.Item>
        </Col>

        <Col span={4} offset={1}>
          <Form.Item
            validateStatus={touched.tax && errors.tax ? "error" : ""}
            label="Tax Rate*"
          >
            <Input
              type="number"
              suffix="%"
              value={values.tax}
              onBlur={handleBlur("tax")}
              onChange={(e) => {
                setHsnId();
                setFieldValue("tax", e.target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <br />
      <Row gutter={0}>
        <Col span={11} offset={0}>
          <p>Recipe</p>
        </Col>
        <Col span={2} offset={11}>
          <Button type="primary" onClick={addToRecipes}>
            + Add{" "}
          </Button>
        </Col>
      </Row>

      <Table columns={recipeColumns} dataSource={values.recipe} />

      <Divider className="mt-0.5 mb-0.5" />
      <div className="flex justify-between">
        {recipeErrors}
        <div>
          <Button className="mr-0.5" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            //  onClick={handleSubmit}
          >
            {selectedSku?.id ? "Update SKU" : "Create SKU"}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default SkusCreateUpdateForm;
