import { Form, Modal, Input, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { UOMS } from "../../../assets/constants";
import { connect } from "react-redux";
import { getList } from "../../../actions/dataActions";
import { formatGroups } from "../../../common/functions/formatGroups";
import { Formik } from "formik";
import * as yup from "yup";

const { Option } = Select;

const CreateOrUpdateMaterials = ({
  getGroups,
  getSubGroups,
  MAINGROUPS,
  SUBGROUPS,
  visible,
  confirmLoading,
  handleOk,
  selectedMaterial,
  handleCancel,
}) => {
  useEffect(() => {
    getGroups();
    getSubGroups();
    //eslint-disable-next-line
  }, []);

  const [filteredSubGroups, setFilteredSubGroups] = useState([]);

  const filterSubGroups = (maingroup) => {
     try{
  
      // get main group id 
      const mainGroup = MAINGROUPS.find((i)=>{
        if(i?.value === maingroup){
          return i?.id;
        }
      });

      // filter subgroups
      const subGroups = SUBGROUPS?.filter((i)=> i?.main_group_id === mainGroup?.id);
      setFilteredSubGroups(subGroups.length > 0 ? subGroups : []);
    
     }catch(err){
       console.log(err);
       window.alert(err);
     }

  }

  useEffect(()=>{
     if(selectedMaterial?.maingroup){
       filterSubGroups(selectedMaterial?.maingroup);
     }
  },[])
  const getInitalValues = (selectedMaterial) => {
    if (selectedMaterial?.id) {
      const { name, maingroup, status, subgroup, tax, uom } = selectedMaterial;

      return {
        name,
        maingroup,
        status,
        subgroup,
        tax,
        uom,
      };
    }

    return {
      name: "",
      status: "active",
      maingroup: "",
      subgroup: "",
      uom: "",
      tax: "",
    };
  };

  const getValidationSchema = () => {
    return {
      name: yup.string().required("Please enter a Material Name"),
      maingroup: yup.string().required("Please enter a Main Group"),
      subgroup: yup.string(),
      uom: yup.string().required("Please enter a UOM"),
      tax: yup.number().required("Please enter a Tax Rate"),
      status: yup
        .mixed()
        .oneOf(
          ["active", "inactive"],
          "Status must be either active or inactive"
        ),
    };
  };

  const checkAndEnterHandler = (finalObject, isEditMode) => {
    if (isEditMode) finalObject["id"] = selectedMaterial?.id;
    handleOk(finalObject, isEditMode);
  };

  return (
    <Formik
      initialValues={getInitalValues(selectedMaterial)}
      validationSchema={yup.object().shape(getValidationSchema())}
      onSubmit={(values) => {
        let finalValues = values;
        let isEditMode = !!selectedMaterial;
        checkAndEnterHandler(finalValues, isEditMode);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        // isSubmitting,
        /* and other goodies */
      }) => (
        <Modal
          destroyOnClose={true}
          title={""}
          visible={visible}
          confirmLoading={confirmLoading}
          onOk={handleSubmit}
          onCancel={handleCancel}
        >
          <div className="flex" style={{ paddingBottom: 20 }}>
            <div className="flex-1">
              <p>Status</p>
              <Switch
                checked={values.status === "active"}
                onChange={(checked) =>
                  checked
                    ? setFieldValue("status", "active")
                    : setFieldValue("status", "inactive")
                }
              />
              &nbsp;
              {values.status === "active" ? "Active" : "Inactive"}
            </div>
          </div>

          <Form layout="vertical">
            <Form.Item
              validateStatus={touched.name && errors.name ? "error" : ""}
              help={errors.name}
              label="Material Name"
              required
              tooltip="e.g., Sugar / Cashew"
            >
              <Input
                value={values.name}
                onBlur={handleBlur("name")}
                onChange={handleChange("name")}
                placeholder="Material Name Here"
              />
            </Form.Item>

            <Form.Item
              validateStatus={
                touched.maingroup && errors.maingroup ? "error" : ""
              }
              required
              help={errors.maingroup}
              label="Main Group"
              style={{
                display: "inline-block",
                width: "calc(50% - 50px)",
                marginRight: 20,
              }}
            >
              <Select
                placeholder="Select a group"
                showSearch
                value={values.maingroup}
                onBlur={handleBlur("maingroup")}
                onChange={(value)=>{
                  setFieldValue("maingroup", value);
                  filterSubGroups(value);
                }}
                style={{ width: "100%" }}
              >
                {MAINGROUPS.map((i) => {
                  return (
                    <Option key={i.text} value={i.value}>
                      {i.text}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              validateStatus={
                touched.subgroup && errors.subgroup ? "error" : ""
              }
              help={errors.subgroup}
              label="Sub Group"
              style={{
                display: "inline-block",
                width: "calc(50% - 12px)",
              }}
            >
              <Select
                placeholder="Select a subgroup"
                showSearch
                value={values.subgroup}
                onBlur={handleBlur("subgroup")}
                onChange={handleChange("subgroup")}
                style={{ width: "100%" }}
              >
                {filteredSubGroups?.map((i) => {
                  return (
                    <Option key={i.text} value={i.value}>
                      {i.text}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              validateStatus={touched.uom && errors.uom ? "error" : ""}
              help={errors.uom}
              label="Unit of Measurement (UoM)"
              required
            >
              <Select
                placeholder="Unit of measurement"
                value={values.uom}
                onBlur={handleBlur("uom")}
                onChange={handleChange("uom")}
                style={{ width: "100%" }}
              >
                {UOMS.map((i) => {
                  return (
                    <Option key={i.text} value={i.value}>
                      {i.text}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Tax Rate (in %)"
              validateStatus={touched.tax && errors.tax ? "error" : ""}
              help={errors.tax}
            >
              <Input
                type={"number"}
                max={100}
                suffix={"%"}
                value={values.tax}
                onBlur={handleBlur("tax")}
                onChange={handleChange("tax")}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
const mapState = (state) => ({
  MAINGROUPS: formatGroups(state.data.grouplist),
  SUBGROUPS: formatGroups(state.data.subgrouplist),
});

const mapDispatch = {
  getGroups: () => getList("groups"),
  getSubGroups: () => getList("subgroups"),
};

export default connect(mapState, mapDispatch)(CreateOrUpdateMaterials);
