import React, { useEffect } from "react";
import { Tabs } from "antd";
import ReportForPaymentTerms from "./reports.paymentTerms";
import ReportOfTopSellers from "./reports.topSellingProduct";
const { TabPane } = Tabs;

const Report = () => {
  return (
    <div>
      <Tabs destroyInactiveTabPane={true} defaultActiveKey="1">
        <TabPane tab={<span>Payment Terms</span>} key="1">
          <ReportForPaymentTerms />
        </TabPane>

        <TabPane tab={<span>Top Selling Products</span>} key="3">
          <ReportOfTopSellers />
        </TabPane>
      </Tabs>
    </div>
  );
};



export default Report;
