import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import {
  DiffOutlined,
  CarryOutOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import GoodsIssuedNotes from "./goods-issued-notes/make.goodsIssuedNotes.main";
import MakeOrder from "./make-order/makeOrder.main";
import Tasks from "./tasks/tasks.main";
const { TabPane } = Tabs;

const MakeScreen = () => {
  const [activeTab, setActiveTab] = useState("1");

  const changeTab = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    document.title = "Make - Invento";
  }, []);

  return (
    <Tabs
      defaultActiveKey="1"
      destroyInactiveTabPane={true}
      activeKey={activeTab}
      onChange={changeTab}
    >
      <TabPane
        tab={
          <span>
            <DiffOutlined />
            Make Orders
          </span>
        }
        key="1"
      >
        <MakeOrder changeTab={changeTab} />
      </TabPane>
      <TabPane
        tab={
          <span>
            <CarryOutOutlined />
            Goods Issued Notes
          </span>
        }
        key="2"
      >
        <GoodsIssuedNotes />
      </TabPane>
      {/* <TabPane
        tab={
          <span>
            <BarsOutlined />
            Tasks
          </span>
        }
        key="3"
      >
        <Tasks />
      </TabPane> */}
    </Tabs>
  );
};

export default MakeScreen;
