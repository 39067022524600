import { Form, Input, Modal, Button, message, Select, Row, Col } from "antd";
import axios from "axios";
import { useState } from "react";

const { Option } = Select;
const TermsModal = ({
  isModalVisible,
  setModalVisible,
  prvType,
  prvName,
  prvID,
  isEdit,
  getPaymentTerms,
}) => {
  const [type, setType] = useState(prvType || "");
  const [name, setName] = useState(prvName || "");

  const cancelHandler = () => {
    setModalVisible(false);
  };

  const okHandler = async () => {
    const finalObject = {
      name: name,
      type: type,
    };

    try {
      let res;
      if (isEdit) {
        res = await axios.patch(`/api/term/${prvID}`, finalObject);
      } else {
        res = await axios.post("/api/term", finalObject);
      }

      if (res.status === 200) {
        message.success(
          isEdit ? "Updated Terms Successfully" : "Added Terms Successfully"
        );
        setType("");
        setName("");
        getPaymentTerms();
        setModalVisible(false);
      }
    } catch (error) {
      message.error("Error updating terms, please try again later");
    }
  };

  return (
    <Modal
      title={isEdit ? "Edit Terms" : "Add Terms"}
      visible={isModalVisible}
      onOk={okHandler}
      onCancel={cancelHandler}
    >
      <Form layout="vertical">
        <Form.Item label="Type" required tooltip="Select the type of terms">
          <Select
            placeholder="Select Type"
            onChange={(value) => setType(value)}
            value={type || undefined}
            disabled={isEdit} // Disable type selection for edit mode
          >
            <Option value="Payment">Payment</Option>
            <Option value="Delivery">Delivery</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Name"
          required
          tooltip="e.g., GST or Cash on Delivery"
        >
          <Input
            required
            placeholder="Name of Terms"
            onChange={(e) => setName(e.target.value)}
            value={name || ""}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TermsModal;
