import { useEffect, useState } from "react";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { Button, Select, Table, Tooltip } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import PriceModal from "./PriceMasterModal";
import { connect } from "react-redux";
import { getList } from "../../../actions/dataActions";
import  { skuType, materialType, item_types } from "../../sales/sales-orders/itemTypes";


const Option = Select;

const PriceMaster = ({
  getItemPrices,
  itemPrices,
  getMaterialsList,
  getSkusList,
  skus,
  materials,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedType, setSelectedType] = useState("sku");
  console.log(selectedItem, "item @25")
  const showModal = (selectedItem) => {
    setModalVisible(true);
    setSelectedItem(selectedItem);
  };

  useEffect(() => {
    getItemPrices();
    getMaterialsList(); 
    getSkusList(); 
  }, []);

  console.log(selectedType, "@ 36")


  const priceCols = [
    {
      title: "Item Id",
      width: 100,
      dataIndex: "id",
      ...useTableSearch("item_id"),
    },
    {
      title: "Name",
      width: 300,
      dataIndex:`${ selectedType === skuType ? "sku" : "name"}`,
      ...useTableSearch(`${ selectedType === skuType ? "sku" : "name"}`),
    },
    {
      title: "Unit Price",
      width: 150,
      dataIndex: "value",
      ...useTableSearch("value"),
    },

    {
      title: "Actions",
      width: 150,
      render: (value, record) => {
        return (
          <>
            <div className="flex">
              <Tooltip title="Edit">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    showModal(record);
                  }}
                  danger
                />
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>

    <div className="flex justify-between">

    <Select style={{width:"10rem"}} onChange={(type) => setSelectedType(type)} defaultValue={item_types[0].type || "sku"}>
    {item_types.map(({ type }) => (
              <Select.Option labelInValue key={type} value={type} >
                {type}
              </Select.Option>
            ))}
      </Select>

      {/* <Button onClick={() => showModal("add")} type="primary">
        <PlusOutlined /> Add Prices
      </Button> */}

    </div>


      <Table
        style={{
          maxWidth: "900px",
        }}
        columns={priceCols}
        dataSource={selectedType === skuType ? skus : materials}
      />

      {isModalVisible && (
        <PriceModal
          setSelectedItem={selectedItem}
          setModalVisible={setModalVisible}
          isModalVisible={isModalVisible}
          selectedItem={selectedItem}
          setSelectedType={selectedType}
          selectedType={selectedType}
          getMaterialsList={getMaterialsList}
          getSkusList={getSkusList}
          
        />
      )}
    </>
  );
};

const mapState = (state) => {
  return {
    itemPrices: state.data.itemPrices,
    materials: state.data.materials,
    skus: state.data.skus,
  };
};

const mapDispatch = {
  getItemPrices: () => getList("itemPrices"),
  getMaterialsList: () => getList("materials"),
  getSkusList: () => getList("skus"),
};

export default connect(mapState, mapDispatch)(PriceMaster);
