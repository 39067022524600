import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Input, Select, Divider, Switch } from "antd";
import {
  CUSTOMER_TYPES,
  SUPPLIER_TYPES,
  TRANSPORTER_TYPES,
  STATES,
  USER_TAGS,
} from "./constants";
import getDataFromPincodeHandler from "./utils";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  validateEmail,
  validateContact,
} from "../../assets/constants/validations";

const { Option } = Select;

//validation schema
const PartnersSchema = yup.object().shape({
  status: yup.mixed().oneOf(["active", "inactive"], "Please select status"),
  //gstin,supplier type, name(required) , email address, website, tags, addresses, poc
  name: yup.string().required("Please Enter the name"),
  gstin: yup.mixed().required("Please Enter the GSTIN"),
  type: yup.mixed(),
  contact: yup.string().required("Please Enter the contact number"),
  website: yup.mixed(),
  tags: yup.mixed(),
  addresses: yup
    .array()
    .min(1)
    .test("improperAddress", "Must enter complete address details", (items) => {
      return !(
        !items.length ||
        items.some(
          ({ pincode, address1, city, state, contact }) =>
            !pincode || !address1 || !city || !state || !contact
        )
      );
    }),
  poc: yup
    .array()
    .test((items) =>
      !items.length || items.some(({ name, contact }) => !name || !contact)
        ? "Please provide name and contact"
        : ""
    ),
});

const CreateOrUpdate = ({
  open,
  selectedPartner,
  onOk,
  onCancel,
  partnerRole,
}) => {
  const getInitialValues = () => {
    if (selectedPartner?.id) {
      const {
        name,
        status,
        gstin,
        type,
        website,
        contact,
        tags,
        addresses,
        poc,
      } = selectedPartner;

      return {
        name,
        status,
        gstin,
        type,
        contact,
        website,
        tags,
        addresses,
        poc,
      };
    }

    return {
      name: "",
      status: "active",
      gstin: "",
      type: "",
      contact: "",
      website: "",
      tags: [],
      addresses: [
        {
          pincode: "",
          address1: "",
          address2: "",
          city: "",
          contact: "",
          state: "",
          country: "",
        },
      ],
      poc: [],
      // poc: [
      //   {
      //     name: "",
      //     role: "",
      //     email: "",
      //     contact: "",
      //   },
      // ],
    };
  };

  const [partnerType, setPartnerType] = useState("");

  useEffect(() => {
    if (selectedPartner?.id) {
      const { id, name, type, email, gstin, contact, website, tags, status } =
        selectedPartner || "";

      const { addresses, poc } = selectedPartner || [];
      setFieldValue("");
      setFieldValue("name", name);
      setFieldValue("type", type);
      setFieldValue("email", email);
      setFieldValue("contact", contact);
      setFieldValue("gstin", gstin);
      setFieldValue("website", website);
      setFieldValue("tags", tags);
      setFieldValue("status", status);
      setFieldValue("addresses", addresses);
      setFieldValue("poc", poc);
    }
  }, []);

  const prepareForOk = () => {
    let finalValues = values;
    onOk(finalValues);
  };

  const pocColumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (value, record, index) => {
        return (
          <Input
            value={value}
            onChange={(e) => {
              let _t = [...values.poc];
              _t[index].name = e.target.value;
              setFieldValue("poc", _t);
            }}
          />
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (value, record, index) => {
        return (
          <Input
            value={value}
            onChange={(e) => {
              let _t = [...values.poc];
              _t[index].role = e.target.value;
              setFieldValue("poc", _t);
            }}
          />
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (value, record, index) => {
        return (
          <Input
            value={value}
            onChange={(e) => {
              let _t = [...values.poc];
              _t[index].email = e.target.value;
              setFieldValue("poc", _t);
            }}
            onBlur={(e) => {
              const email = e.target.value;
              if (email && !validateEmail(email)) {
                alert("Please enter a valid email address.");
                let _t = [...values.poc];
                _t[index].email = "";
                setFieldValue("poc", _t);
              }
            }}
          />
        );
      },
    },
    {
      title: "Contact *",
      dataIndex: "phone",
      render: (value, record, index) => {
        return (
          <Input
            value={value}
            onChange={(e) => {
              const input = e.target.value;
              const { isNumeric } = validateContact(input);
              if (isNumeric) {
                let updatedPoc = [...values.poc];
                updatedPoc[index].phone = input;
                setFieldValue("poc", updatedPoc);
              }
            }}
            onBlur={(e) => {
              const input = e.target.value;
              const { isValidLength } = validateContact(input);
              if (!isValidLength) {
                alert("Phone number must be either 6 or 10 digits long.");
                let updatedPoc = [...values.poc];
                updatedPoc[index].phone = "";
                setFieldValue("poc", updatedPoc);
              }
            }}
            maxLength={10}
          />
        );
      },
    },
    {
      title: "Action",
      render: (value, record, index) => {
        return (
          <Button
            htmlType="button"
            type="primary"
            onClick={() => removeFromPoc(index)}
            disabled={values.poc.length === 1}
          >
            X
          </Button>
        );
      },
    },
  ];

  const getDataFromPincode = async (value, index) => {
    const temp = await getDataFromPincodeHandler(value);
    addressChangeHandler(temp.State, index, "state");
    addressChangeHandler(temp.District, index, "city");

    addressChangeHandler(temp.Name, index, "address1");
    addressChangeHandler(temp.Region, index, "address2");
  };

  const addressChangeHandler = (e, index, key) => {
    let _t = [...values.addresses];
    _t[index][key] = e;
    setFieldValue("addresses", _t);
  };

  const addressColumns = [
    {
      title: "Pin Code *",
      dataIndex: "pincode",
      render: (value, record, index) => (
        <>
          <Input
            style={{ width: 90 }}
            type="number"
            value={value}
            maxLength={5}
            minLength={5}
            onChange={(e) =>
              addressChangeHandler(e.target.value, index, "pincode")
            }
            setFieldTouched
          />
          <Button
            htmlType="button"
            type="link"
            onClick={() => getDataFromPincode(value, index)}
          >
            lookup
          </Button>
        </>
      ),
    },
    {
      title: "Address Line1 *",
      dataIndex: "address1",
      render: (value, record, index) => (
        <Input.TextArea
          value={value}
          onChange={(e) =>
            addressChangeHandler(e.target.value, index, "address1")
          }
          onBlur={handleBlur("addresses")}
        />
      ),
    },
    {
      title: "Address Line2",
      dataIndex: "address2",
      render: (value, record, index) => (
        <Input.TextArea
          value={value}
          onChange={(e) =>
            addressChangeHandler(e.target.value, index, "address2")
          }
        />
      ),
    },

    {
      title: "City *",
      dataIndex: "city",
      render: (value, record, index) => (
        <Input
          value={value}
          onChange={(e) => addressChangeHandler(e.target.value, index, "city")}
        />
      ),
    },
    {
      title: "Contact*",
      dataIndex: "contact",
      width: 180,
      render: (value, record, index) => (
        <>
          <Input
            type="text"
            value={value}
            onChange={(e) => {
              const input = e.target.value;
              const { isNumeric } = validateContact(input);
              if (isNumeric) {
                addressChangeHandler(input, index, "contact");
              }
            }}
            onBlur={(e) => {
              const input = e.target.value;
              const { isValidLength } = validateContact(input);
              if (!isValidLength) {
                alert("Contact must be either 6 or 10 digits long.");
                addressChangeHandler("", index, "contact");
              }
            }}
            maxLength={10}
          />
        </>
      ),
    },
    {
      title: "State *",
      dataIndex: "state",
      width: 180,
      render: (value, record, index) => (
        <>
          <Input
            type="text"
            value={value}
            onChange={(e) =>
              addressChangeHandler(e.target.value, index, "state")
            }
          />
        </>
      ),
    },

    {
      title: "Country",
      dataIndex: "country",
      render: (value, record, index) => (
        <Input
          style={{ width: 90 }}
          value={value}
          onChange={(e) =>
            addressChangeHandler(e.target.value, index, "country")
          }
        />
      ),
    },

    {
      title: "Actions",
      render: (value, record, index) => (
        <Button
          htmlType="button"
          type="primary"
          onClick={() => removeFromAddress(index)}
          disabled={values.addresses.length === 1}
        >
          X
        </Button>
      ),
    },
  ];

  const removeFromPoc = (index) => {
    let _pointsOfContact = [...values.poc];
    _pointsOfContact.splice(index, 1);
    setFieldValue("poc", _pointsOfContact);
  };

  const removeFromAddress = (index) => {
    let _addresses = [...values.addresses];
    _addresses.splice(index, 1);
    setFieldValue("addresses", _addresses);
  };

  const addPocHandler = (values, setFieldValue) => {
    let _pointsOfContact = [...values.poc];
    _pointsOfContact.push({
      name: "",
      role: "",
      email: "",
      contact: "",
    });

    setFieldValue("poc", _pointsOfContact);
  };

  const addAddressHandler = (values, setFieldValue) => {
    console.log("clicked!", values, setFieldValue);
    let _addresses = [...values.addresses];
    _addresses.push({
      pincode: "",
      address1: "",
      address2: "",
      city: "",
      contact: "",
      state: "",
      country: "",
    });
    console.log(_addresses);

    setFieldValue("addresses", _addresses);
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: PartnersSchema,
    onSubmit: prepareForOk,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = formik;

  // const checkMandatoryFields = (addresses, pointsOfContact) => {
  //   let error = null;

  //   const checkIfEmpty = (object) => {
  //     return Object.values(object).some((x) => x === null || x === "");
  //   };

  //   if (!addresses.length) {
  //     error = "Enter at least one address";
  //   }

  //   for (const address of addresses) {
  //     const { address1, pincode, country, state } = address;
  //     console.log(address);
  //     if (
  //       checkIfEmpty({
  //         address1,
  //         pincode,
  //         country,
  //         state,
  //       })
  //     ) {
  //       error = "Address / Pincode / Country / State cannot be empty";
  //       break;
  //     }
  //   }

  //   if (pointsOfContact.length) {
  //     for (const poc of pointsOfContact) {
  //       if (!poc.name) {
  //         error = "Point of Contact Must have a name";
  //         break;
  //       }
  //     }
  //   }

  //   return error;
  // };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex mb-1">
        <div className="mr-1">{partnerRole} Status:</div>
        <div>
          <Switch
            checked={values.status === "active"}
            onChange={(checked) =>
              checked
                ? setFieldValue("status", "active")
                : setFieldValue("status", "inactive")
            }
          />
          &nbsp;
          {values.status === "active" ? "Active" : "Inactive"}
        </div>
      </div>

      <Row gutter={0}>
        <Col span={12}>
          <div style={{ display: "flex" }}>
            <p>{partnerRole} Name*</p>
          </div>
          <Input
            value={values.name}
            onChange={handleChange("name")}
            onBlur={handleBlur("name")}
          />
          <p style={{ color: "red" }}>
            {getErrorMsg(touched.name, errors.name)}
          </p>
        </Col>

        <Col span={10} offset={2}>
          <Row gutter={0}>
            <Col span={12}>
              <p>GSTIN</p>
              <Input value={values.gstin} onChange={handleChange("gstin")} />
              <p style={{ color: "red" }}>
                {getErrorMsg(touched.gstin, errors.gstin)}
              </p>
            </Col>

            <Col span={10} offset={2}>
              <p>{partnerRole} Type</p>
              <Select
                // onBlur={handleBlur("type")}
                style={{ width: "100%" }}
                value={values.type}
                onChange={(v) => setFieldValue("type", v)}
              >
                {(partnerRole?.toLowerCase() === "customer"
                  ? CUSTOMER_TYPES
                  : partnerRole?.toLowerCase() === "supplier"
                  ? SUPPLIER_TYPES
                  : TRANSPORTER_TYPES
                ).map((i) => {
                  return (
                    <Option key={i.text} value={i.value}>
                      {i.text}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />

      <Row gutter={0}>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <p>Email Address</p>
              <Input
                type="email"
                value={values.email}
                onChange={handleChange("email")}
              />
            </Col>
            <Col span={10} offset={2}>
              <p>Contact</p>
              <Input
                value={values.contact}
                onChange={(e) => {
                  const input = e.target.value;
                  const { isNumeric } = validateContact(input);
                  if (isNumeric) {
                    handleChange("contact")(e);
                  }
                }}
                onBlur={(e) => {
                  const input = e.target.value;
                  const { isValidLength } = validateContact(input);
                  if (!isValidLength) {
                    alert("Contact must be either 6 or 10 digits long.");
                    handleChange("contact")({ target: { value: "" } });
                  }
                }}
                maxLength={10}
              />
              <p style={{ color: "red" }}>
                {getErrorMsg(touched.contact, errors.contact)}
              </p>
            </Col>

            <Col span={10} style={{ marginTop: "16px" }}>
              <p>Website</p>
              <Input
                // type={"url"}
                value={values.website}
                onChange={handleChange("website")}
              />
            </Col>
          </Row>
        </Col>

        <Col span={10} offset={2}>
          <p>Tags</p>
          <Select
            value={values.tags}
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Tags"
            onChange={(tags) => setFieldValue("tags", tags)}
          >
            {USER_TAGS.map((tagName) => {
              return <Option key={tagName}>{tagName}</Option>;
            })}
          </Select>
        </Col>
      </Row>
      <br />

      <Divider />

      <div>
        <div className="flex justify-between mb-1 items-end">
          <div>Addresses</div>
          <div>
            <Button
              onClick={() => addAddressHandler(values, setFieldValue)}
              type="primary"
              htmlType="button"
            >
              + Add an address
            </Button>
          </div>
        </div>

        <Table columns={addressColumns} dataSource={values.addresses} />
        <small style={{ color: "red" }}>
          {getErrorMsg(touched.addresses, errors.addresses)}
        </small>
      </div>

      <br />
      <Divider />

      {partnerType === "B2C" ? (
        <div></div>
      ) : (
        <div>
          <div className="flex justify-between mb-1 items-end">
            <div>Points of Contact</div>
            <div>
              <Button
                htmlType="button"
                onClick={() => addPocHandler(values, setFieldValue)}
                type="primary"
              >
                + Add a point of contact
              </Button>
            </div>
          </div>

          <Table columns={pocColumns} dataSource={values.poc} />
        </div>
      )}

      <Divider className="mt-0.5 mb-0.5" />
      <div className="flex justify-end">
        <Button className="mr-0.5" htmlType="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          // onClick={prepareForOk}
        >
          {selectedPartner?.id ? "Update" : "Create"} {partnerRole}
        </Button>
      </div>
    </form>
  );
};

const getErrorMsg = (touched, error) => {
  if (touched && error) return error;
  return "";
};

export default CreateOrUpdate;
