import { Modal, Input, Table, message } from "antd";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { skuType } from "./itemTypes";
import { validateSkusInFill } from "../../../common/functions/validateSkusInFill";

const FillSoModal = ({ open, so, onClose, onOk, skus, materials }) => {
  const [skuData, setSkuData] = useState([]);

  useEffect(() => {
    // console.clear();
    if (so) setSkuData(so.items);
  }, []);

  const handleOkClicker = () => {
    const finalFillsArray = [];

    const validationError = validateSkusInFill(skuData);
    if (validationError) {
      message.error(validationError);
      return;
    }

    skuData.forEach(({ item_id, item_type, quantity, uom, fill_quantity }) => {
      if (fill_quantity) {
        finalFillsArray.push({
          item_id,
          item_type,
          quantity,
          fill_quantity: parseInt(fill_quantity),
          uom: "Pc",
        });
      }
    });
    let _temp = {
      fills: finalFillsArray,
      so_id: so?.id,
    };
    // return;
    onOk(_temp);

    // let _temp = {
    //   fills: finalFillsArray,
    //   so_id: so?.id,
    // };
    // // return;
    // onOk(_temp);
  };

  const fillColumns = [
    {
      title: "Item ID",
      dataIndex: "item_id",
    },
    {
      title: "Item Name",
      dataIndex: "item_id",
      render: (value, row) => {
        const isSku = row?.item_type?.toLowerCase() === skuType;
        const dataSource = isSku ? skus : materials;
        const item = dataSource?.find(({ id }) => id === value);
        const key = isSku ? "sku" : "name";
        return item?.[key];
      },
    },
    {
      title: "Total Inwarded Quantity",
      dataIndex: "quantity",
      render: (value, record) => value + " Pc",
    },
    {
      title: "Current Pending Quantity",
      dataIndex: "pending",
      render: (value, record, index) => value + " Pc",
    },
    {
      title: "New Pending Quantity",
      // dataIndex: "pending",
      render: (value, record, index) => {
        return (
          parseFloat(record.pending) -
          (record.fill_quantity ? parseFloat(record.fill_quantity) : 0) +
          " " +
          "Pc"
          // record.uom
        );
      },
    },
    {
      title: "Fill Quantity",
      dataIndex: "fill_quantity",
      render: (value, record, index) => {
        return (
          <Input
            type={"number"}
            max={record.pending}
            min={0}
            suffix="Pc"
            value={value}
            onChange={(e) => {
              let _temp = [...skuData];
              _temp[index].fill_quantity = e.target.value;
              setSkuData(_temp);
            }}
          />
        );
      },
    },
  ];

  return (
    <Modal
      destroyOnClose
      style={{ top: 10 }}
      title="Fill Sales Order"
      visible={open}
      onOk={handleOkClicker}
      onCancel={onClose}
      width={1200}
    >
      <Table columns={fillColumns} dataSource={skuData} pagination={false}/>
    </Modal>
  );
};

const mapState = (state) => {
  return {
    skus: state.data.skus,
    materials: state.data.materials,
  };
};

const mapDispatch = {};

export default connect(mapState, mapDispatch)(FillSoModal);
