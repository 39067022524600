import React, { useState, useEffect } from "react";
import { Button, Table, Tooltip, message } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { getList } from "../../../actions/dataActions";
import { connect } from "react-redux";
import GroupsModal from "./GroupsModal";
import axios from "axios";
import DeleteConfirm from "./DeleteConfirm";
import useTableSearch from "../../../common/hooks/useTableSearch";

const Groups = ({ getGroups, getSubGroups, list }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const deleteHandler = async (id) => {
    try {
      await axios.delete("/api/group/" + id);
      getGroups();
      getSubGroups();

    } catch (err) {
      message.error("Error Deleting Groups");
    }
  };

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const columns = [
    {
      title: "Group ID",
      dataIndex: "id",
      key: "id",
      ...useTableSearch("id"),
    },
    {
      title: "Groups",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      width: 150,
      render: (value, record) => {
        return (
          <>
            <div className="flex">
              <DeleteConfirm id={record.id} handleDelete={deleteHandler} title={record.name}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    danger
                  />
              </DeleteConfirm>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Button style={{marginBottom: "1rem"}} onClick={showModal} type="primary">
        <PlusOutlined /> Add Groups
      </Button>
      <Table dataSource={list} columns={columns} />
      <GroupsModal
        getGroups={getGroups}
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

const mapState = (state) => ({
  list: state.data.grouplist,
});

const mapDispatch = {
  getGroups: () => getList("groups"),
  getSubGroups: () => getList("subgroups")
};

export default connect(mapState, mapDispatch)(Groups);
