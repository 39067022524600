import {
  Table,
  DatePicker,
  Row,
  Col,
  Input,
  Select,
  Button,
  Divider,
  Popconfirm,
  message
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../assets/constants";
import { roundValue } from "../../../common/functions/roundValue";

const filterOption = (input, option) =>
  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const MoCreateEditModal = ({
  mo,
  skuList,
  taskList,
  onOk,
  onCancel,
  onDelete,
  createMode,
  locId,
  changeTab,
}) => {
  const {
    location_id,
    make_status,
    sku_id,
    total_quantity,
    comments,
    order_status,
    material_cost,
    labour_cost,
    labour_schedule,
    material_schedule,
  } = mo || "";

  const { due_date } = mo || new Date().toISOString();

  const [dueDate, setDueDate] = useState(due_date);
  const [skuId, setSkuId] = useState(sku_id);
  const [selectedSku, setSelectedSku] = useState({});
  const [totalQuantity, setTotalQuantity] = useState(total_quantity);
  const [orderComments, setOrderComments] = useState(comments);

  const [labourSchedule, setLabourSchedule] = useState(labour_schedule);
  const [orderStatus, setOrderStatus] = useState(order_status);
  const [materialCost, setMaterialCost] = useState(material_cost);
  const [labourCost, setLabourCost] = useState(labour_cost);

  useEffect(() => {
    if (sku_id) {
      setSelectedSku(skuList?.find((el) => el?.sku_id === sku_id));
    }
  }, []);

  const okHandler = (_taskArray) => {
    let _t = [];
    _taskArray.forEach((_task) => {
      _t.push({
        id: _task.id,
        status: "Started",
      });
    });

    let _finalObject = {
      due_date: moment(dueDate).toISOString(),
      location_id: locId,
      make_status: "Awaiting",
      sku_id: skuId,
      uom: "Pc",
      total_quantity: totalQuantity,
      comments: orderComments,
      labour_schedule: _t,
    };
    if (mo?.id) _finalObject.mo_id = mo.id;

    if (parseFloat(_finalObject.total_quantity).toString().includes(".")) {
      message.error("Quantity (in Pcs) cannot be in decimals!");
      return;
    }
    // if (selectedSku?.available < _finalObject.total_quantity){
    //   message.error(`Not enough stock in inventory. Just ${selectedSku.available} pc stock available`);
    //   return;
    // }

    onOk(_finalObject);
  };

  const handleDeleteClick = () => {
    onDelete(mo?.id);
  };

  const cancelHandler = () => onCancel();

  return (
    <>
      <div>
        <Row gutter={0}>
          <Col span={6} offset={0}>
            <div style={{ display: "flex" }}>
              <p>SKU</p>
              <p
                style={{
                  color: "crimson",
                  fontSize: "22px",
                  margin: "-5px 0 0 5px",
                }}
              >
                *
              </p>
            </div>
            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: 300 }}
              value={selectedSku?.sku}
              onChange={(_skuId) => {
                setSkuId(_skuId);
                const sku = skuList?.find((sku) => sku?.sku_id === _skuId);
                setSelectedSku(sku);
              }}
            >
              {skuList.map(({ sku_id, sku }) => {
                return (
                  <Select.Option key={sku_id} value={sku_id}>
                    {sku} 
                  </Select.Option>
                );
              })}
            </Select>
            {/* {JSON.stringify(selectedSku)} */}
          </Col>

          <QtySelector
            totalQuantity={totalQuantity}
            setTotalQuantity={setTotalQuantity}
            weightPerUnit={selectedSku?.quantity}
            uom={selectedSku?.uom}
          />

          <Col span={4} offset={2}>
            <div style={{ display: "flex" }}>
              <p>Due Date</p>
              <p
                style={{
                  color: "crimson",
                  fontSize: "22px",
                  margin: "-5px 0 0 5px",
                }}
              >
                *
              </p>
            </div>
            <DatePicker
              showTime={{ use12Hours: true }}
              allowClear={false}
              defaultValue={moment(dueDate)}
              value={moment(dueDate)}
              format={DATE_TIME_FORMAT}
              onChange={(date) => setDueDate(moment(date).toISOString())}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col span={12}>
            <div>Comments</div>
            <Input.TextArea
              value={orderComments}
              onChange={(e) => setOrderComments(e.target.value)}
              autoSize
            />
          </Col>
        </Row>

        <br />

        <Row className="w-100" style={{ marginTop: 10 }}>
          <Col className="w-100">
          
            <br />

            <TaskSelections
              createMode={createMode}
              okHandler={okHandler}
              cancelHandler={cancelHandler}
              labourSchedule={labourSchedule}
              mo={mo}
              handleDeleteClick={handleDeleteClick}
              getTaskList={() => {
                let _t = [];
                taskList.forEach((el) => {
                  _t.push({
                    ...el,
                    key: el.id,
                  });
                });
                return _t;
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const QtySelector = ({
  totalQuantity,
  setTotalQuantity,
  uom,
  weightPerUnit,
}) => {
  const [pcs, setPcs] = useState();
  const [quantity, setQuantity] = useState();
  useEffect(() => {
    // if (totalQuantity) setQuantity(totalQuantity);
    if (totalQuantity) setPcs(totalQuantity);
  }, []);

  useEffect(() => {
    if (pcs) {
      let q = parseFloat((weightPerUnit * pcs).toFixed(1));
      setQuantity(q);
      // setTotalQuantity(q);
      setTotalQuantity(pcs);
    }
  }, [pcs]);

  useEffect(() => {
    if (quantity) {
      setPcs(parseFloat((quantity / weightPerUnit).toFixed(1)));
      // setTotalQuantity(quantity);
    }
  }, [quantity]);

  return (
    <>
      <Col span={3} offset={1}>
        <div style={{ display: "flex" }}>
          <p>Quantity (in Pcs)</p>
          <p
            style={{
              color: "crimson",
              fontSize: "22px",
              margin: "-5px 0 0 5px",
            }}
          >
            *
          </p>
        </div>
        <Input
          type="number"
          value={pcs}
          min={1}
          disabled={!uom}
          onChange={(e) => setPcs(e.target.value)}
        />
      </Col>
      <Col
        style={{
          alignItems: "flex-end",
          justifyContent: "center",
          display: "flex",
          margin: 10,
          fontWeight: "bold",
        }}
      >
        OR
      </Col>
      <Col span={3} offset={0}>
        <div style={{ display: "flex" }}>
          <p>Quantity (in {uom})</p>
          <p
            style={{
              color: "crimson",
              fontSize: "22px",
              margin: "-5px 0 0 5px",
            }}
          >
            *
          </p>
        </div>
        <Input
          type="number"
          value={quantity}
          disabled={!uom}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </Col>

      {/* <div>{JSON.stringify(totalQuantity)}</div> */}
    </>
  );
};

const TaskSelections = ({
  getTaskList,
  labourSchedule,
  okHandler,
  cancelHandler,
  createMode,
  mo,
  handleDeleteClick,
}) => {
  const taskList = getTaskList();

  const getSelectedRowKeys = () => {
    let _k = [];
    labourSchedule?.forEach((_s) => {
      _k.push(taskList?.find((el) => el.id === _s.task_id).id);
    });

    return _k;
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState(getSelectedRowKeys());
  const [selectedTasks, setSelectedTasks] = useState([]);

  // useEffect(() => {
  //   labourSchedule?.forEach(_s => {
  //     selectRow(taskList?.find(el => el.id === _s.task_id));
  //   });
  // }, []);

  const taskCols = [
    {
      title: "ID",
      dataIndex: "id",
    },

    {
      title: "Task Name",
      dataIndex: "name",
    },

    {
      title: "Resource Allotted",
      dataIndex: "resource",
    },
    {
      title: "Cost",
      dataIndex: "cost_per_hour",
      render: (text, row, index) => {
        return (
          <div>
            Rs {text} /Hr ( Rs {roundValue(row?.cost_per_min)} /Min )
          </div>
        );
      },
    },
    {
      title: "Minutes per unit",
      dataIndex: "min_per_unit",
    },
    {
      title: "Cost per unit",
      dataIndex: "cost_per_unit",
    },
  ];

  useEffect(() => {
    let _temp = [];
    selectedRowKeys.forEach((key) => {
      _temp.push(taskList.find((el) => el.id === key));
    });
    setSelectedTasks(_temp);
  }, [selectedRowKeys]);

  const selectRow = (record) => {
    const _selectedRowKeys = [...selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }
    setSelectedRowKeys(_selectedRowKeys);
  };

  const onSelectedRowKeysChange = (_selectedRowKeys) => {
    setSelectedRowKeys(_selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  const [deletePopup, setDeletePopup] = useState(false);

  const showPopconfirm = () => {
    setDeletePopup(true);
  };

  return (
    <>
      {/* <Table
        rowSelection={rowSelection}
        columns={taskCols}
        dataSource={taskList}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
      /> */}

      {/* <Divider className="mt-0.5 mb-0.5" /> */}

      <div className="flex justify-between">
        <div>
          {mo?.fill_status === "NA" && (
            <Popconfirm
              title="Do you want to Delete this order?"
              placement="right"
              okText="Delete"
              cancelText="cancel"
              onConfirm={handleDeleteClick}
              onCancel={() => setDeletePopup(false)}
            >
              <Button type="danger" onClick={showPopconfirm}>
                Delete Order
              </Button>
            </Popconfirm>
          )}
        </div>
        <div>
          <Button className="mr-0.5" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => okHandler(selectedTasks)}>
            {createMode ? "Create Order" : "Edit Order"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MoCreateEditModal;
