import React, { useEffect, useState } from "react";
import {
  Table,
  Tooltip,
  Button,
  Row,
  Col,
  Modal,
  Input,
  Select,
  DatePicker,
  Space,
  message,
} from "antd";
import { ScissorOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { useRef } from "react";
// import { MAINGROUPS } from "../../../assets/constants";
import { getList } from "../../../actions/dataActions";
import LocationSelect from "../../../common/components/locationSelect";
import { connect } from "react-redux";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { formatGroups } from "../../../common/functions/formatGroups";
import { isEmpty } from "../../../common/functions/validateInputs";
import * as yup from "yup";
import { Formik } from "formik";

const DATE_FORMAT = "DD-MM-YYYY hh:mm:ss";

const MatInvSummary = ({ getGroups, locationId, MAINGROUPS }) => {
  //table data
  const [data, setData] = useState([]);

  //Stock Adjustment Card Modal
  const [adjustVisible, setAdjustVisible] = useState(false);
  const [adjustLoading, setAdjustLoading] = useState(false);
  const [adjustModalText, setAdjustModalText] = useState("");
  const [adjustModalData, setAdjustModalData] = useState([]);

  //filters
  const [viewAt, setViewAt] = useState(moment());

  const getValidationSchema = () => {
    return {
      adjust_current_stock_to: yup.number().required("Please enter the value"),
      quality_change: yup.number(),
      value_change: yup.number(),
      instock: yup.number(),
      committed: yup.number(),
      available: yup.number(),
      adjust_date: yup.mixed(),
      material_name: yup.string(),
      location_name: yup.string(),
    };
  };

  useEffect(() => {
    listAPICall();
    getGroups();
    //eslint-disable-next-line
  }, [locationId, getGroups]);

  const listAPICall = async (date = viewAt) => {
    let { data } = await axios.post(`/api/inventory/material/list`, {
      location_id: locationId,
      inv_date: date,
    });
    setData(data);
  };
 
  const columns = [
    {
      title: "Material ID",
      dataIndex: "material_id",
      ...useTableSearch("material_id"),
    },
    {
      title: "Material Name",
      dataIndex: "name",
      ...useTableSearch("name"),
      render: (value) => <b>{value}</b>,
    },
    {
      title: "Sub Group",
      dataIndex: "subgroup",
      ...useTableSearch("subgroup"),
    },
    {
      title: "Main Group",
      dataIndex: "maingroup",
      filters: formatGroups(MAINGROUPS),
      filterMultiple: true,
      onFilter: (value, record) => record.maingroup.indexOf(value) === 0,
    },
    {
      title: "Units",
      dataIndex: "unit",
      ...useTableSearch("unit"),
      render: (value) => <span>{value}</span>,
    },
    {
      title: "In Stock",
      dataIndex: "instock",
      filters: [{ text: "Select Only Non-zero Stock", value: 0 }],
      onFilter: (value, record) => record.instock !== 0,
      sorter: (x, y) => x.instock - y.instock,
    },
    { title: "Committed Stock", dataIndex: "committed" },
    { title: "Available Stock", dataIndex: "available" },

    {
      title: "Actions",
      render: (value, record) => {
        return (
          <>
            <Tooltip title="Adjust">
              <Button
                type="primary"
                shape="circle"
                icon={<ScissorOutlined />}
                onClick={() => handleAdjustCard(record)}
                danger
              />
            </Tooltip>
          </>
        );
      },
    },
  ];
  const handleAdjustCard = (x) => {
    setAdjustModalText("Create New Stock Adjustment");
    setAdjustVisible(true);
    x.location_id = locationId;
    x.inv_date = moment().toISOString();
    x.adjust = 0;
    setAdjustModalData(x);
  };

  const handleAdjustOk = async () => {
    setAdjustLoading(true);
    let apiRequest = {
      location_id: adjustModalData.location_id,
      inv_date: adjustModalData.inv_date,
      material_id: adjustModalData.material_id,
      quantity: adjustModalData.adjust - adjustModalData.instock,
      value:
        adjustModalData.avg_value *
        (adjustModalData.adjust - adjustModalData.instock),
    };

    let _mandatoryFields = {
      adjust_current_stock_to: adjustModalData.adjust,
    };

    let error = isEmpty(_mandatoryFields);

    if (error) {
      setAdjustVisible(true);
      message.error(`${error}`);
    }
    if (!error) {
      await axios.post(`/api/inventory/material/adjust`, apiRequest);
      setAdjustVisible(false);
    }
    setAdjustLoading(false);
    listAPICall(adjustModalData?.inv_date);
    message.success("Stock Data Adjusted Successfully");
  };

  const handleAdjustCancel = () => {
    setAdjustVisible(false);
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          {/* Inventory Location: */}
          <LocationSelect />
        </Col>
        <Col span={6} offset={12}>
          View At :{" "}
          <DatePicker
            showTime
            disabledDate={(d) => !d || d.isAfter(moment())}
            allowClear={false}
            style={{ width: "100%" }}
            value={viewAt}
            format={DATE_FORMAT}
            onChange={(dt, dtstr) => {
              const _date = moment(dt).toISOString();
              setViewAt(moment(_date));
              listAPICall(moment(_date));
            }}
          />
        </Col>
      </Row>
      <br />
      <Table columns={columns} dataSource={data} />
      <Formik
        initialValues={adjustModalData}
        validationSchema={yup.object().shape(getValidationSchema())}
        onSubmit={(values) => {
          let finalValues = values;
          let isEditMode = !!adjustModalData;
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Modal
            title="Stock Adjustment Card"
            visible={adjustVisible}
            confirmLoading={adjustLoading}
            onOk={handleAdjustOk}
            onCancel={handleAdjustCancel}
            width={800}
          >
            <br />

            <Row gutter={0}>
              <Col span={6} offset={0}>
                <div style={{ display: "flex" }}>
                  <p>Adjust Current Stock To</p>
                  <p
                    style={{
                      color: "crimson",
                      fontSize: "22px",
                      margin: "-4px 0 0 4px",
                    }}
                  >
                    *
                  </p>
                </div>
                <Input
                  suffix="UoM"
                  value={adjustModalData.adjust}
                  onChange={(v) => {
                    setAdjustModalData({
                      ...adjustModalData,
                      ...{
                        adjust: v.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col span={6} offset={3}>
                <div style={{ display: "flex" }}>
                  <p>Quality Change</p>
                </div>
                <Input
                  suffix="UoM"
                  style={{ width: "100%" }}
                  value={adjustModalData.adjust - adjustModalData.instock}
                  disabled
                />
              </Col>
              {/* <Col span={6} offset={3}>
                <div style={{ display: "flex" }}>
                  <p>Value Change</p>
                </div>
                <Input
                  suffix="INR"
                  style={{ width: "100%" }}
                  value={
                    adjustModalData.avg_value *
                    (adjustModalData.adjust - adjustModalData.instock)
                  }
                  disabled
                />
              </Col> */}
              <Col span={6} offset={3}>
                <p>In Stock</p>
                <Input
                  suffix="UoM"
                  style={{ width: "100%" }}
                  value={
                    !adjustModalData.adjust
                      ? adjustModalData.instock
                      : adjustModalData.adjust
                  }
                  disabled
                />
              </Col>
            </Row>
            <br />

            <Row gutter={0}>
              <Col span={6} offset={0}>
                <p>Committed</p>
                <Input
                  suffix="UoM"
                  style={{ width: "100%" }}
                  value={adjustModalData.committed}
                  disabled
                />
              </Col>
              <Col span={6} offset={3}>
                <p>Available</p>
                <Input
                  suffix="UoM"
                  style={{ width: "100%" }}
                  value={
                    !adjustModalData.adjust
                      ? adjustModalData.available
                      : adjustModalData.adjust - adjustModalData.committed
                  }
                  disabled
                />
              </Col>
              <Col span={6} offset={3}>
                <div style={{ display: "flex" }}>
                  <p>Adjust Date</p>
                </div>
                <DatePicker
                  showTime
                  style={{ width: "100%" }}
                  value={moment(adjustModalData.inv_date, DATE_FORMAT)}
                  format={DATE_FORMAT}
                  disabled
                />
              </Col>
            </Row>
            <br />

            <Row gutter={0}>
              <Col span={6} offset={0}>
                <p>Material Name</p>
                <Input
                  style={{ width: "100%" }}
                  value={adjustModalData.name}
                  disabled
                />
              </Col>
              {/* <Col span={6} offset={3}>
                <div style={{ display: "flex" }}>
                  <p>Location Name</p>
                </div>
                <Input
                  style={{ width: "100%" }}
                  value={adjustModalData.location_name}
                  disabled
                />
              </Col> */}
            </Row>
            <br />
          </Modal>
        )}
      </Formik>
    </>
  );
};

const mapState = (state) => {
  return {
    MAINGROUPS: state.data.grouplist,
    locationId: state.data?.selectedLocation?.id,
  };
};
const mapDispatch = {
  getGroups: () => getList("groups"),
};

export default connect(mapState, mapDispatch)(MatInvSummary);
